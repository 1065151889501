/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { changeLanguage } from "locales/i18n";
import { langs } from "locales/languages";
import { globalStyles } from "consts/spaces";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

const LanguageContainer = styled.div`
  .language a {
    color: ${(props) => props.theme.colorTextInverse};
    opacity: 0.6;

    &::after {
      content: "|";
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: ${(props) => props.theme.colorTextDisabled};
      padding: 0 ${globalStyles.spaces[150]};
    }

    &:last-child::after {
      display: none;
    }

    &.active,
    &:hover {
      opacity: 1;
    }

    &.active {
      pointer-events: none;
    }

    &.dark {
      color: ${(props) => props.theme.colorTextDisabled};

      &.active,
      &:hover {
        color: ${(props) => props.theme.colorLinkDefault};
      }
    }
  }
`;

function Languages({
  center,
  setLang,
}: {
  center?: boolean;
  setLang?: (lang: string) => void;
}) {
  const [currentLang, setCurrentLang] = useState<string>("ka");
  const navigate = useNavigate();
  const location = useLocation();
  const changeLang = (lang: string) => {
    setCurrentLang(lang);
    changeLanguage(lang);
    if (setLang) {
      setLang(lang);
    }

    changeLanguageFunc(lang);
  };

  useEffect(() => {
    const path = location.pathname.split("/"); // Split the pathname into parts
    const language = path[1];

    if (language === "en") {
      changeLang("en");
    } else {
      changeLang("ka");
    }
  }, []);

  const changeLanguageFunc = (ln: string) => {
    const currentUlr = location.pathname;

    if (ln === "ka") {
      if (currentUlr === "/en") {
        navigate("/");
      } else {
        navigate(currentUlr.replace("en/", "") + location?.search);
      }
    } else {
      if (currentUlr === "/") {
        navigate("/en");
      } else {
        if (!currentUlr.includes("en")) {
          navigate("en" + currentUlr + location?.search);
        }
      }
    }
  };

  return (
    <LanguageContainer>
      <div
        className={`language display-flex ${center ? "justify-center" : "justify-flex-end"}`}
      >
        {langs.map((item) => (
          <a
            key={item.key}
            className={`${currentLang === item.key ? "active" : ""} dark`}
            onClick={(e) => {
              e.preventDefault();
              changeLang(item.key);
            }}
            href="."
          >
            {item.name}
          </a>
        ))}
      </div>
    </LanguageContainer>
  );
}

export default Languages;
