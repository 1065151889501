import { FC, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import HeaderTop from "components/parts/header/top";
import { HeaderContainer } from "components/parts/header/styled";
import HeaderSearch from "components/parts/header/search";
import BrandHeader from "components/parts/header/brand";
import { ICategories } from "types/project";
import GlobalMenu from "components/parts/header/global";
import MobileMenu from "components/parts/header/mobileMenu";
import { useTranslation } from "react-i18next";
import { API } from "services/API/API";
import { useOnClickOutside } from "helpers";
import DesktopMenu from "components/parts/header/desktopMenu";

interface IProps {
  toggleTheme: () => void;
  lang?: string;
  dark: boolean;
  setLang?: (lang: string) => void;
  setLoader: (load: boolean) => void;
}
export interface IMenu {
  id: number;
  title: string;
  slug: string;
  productBrands: { id: number; slug: string; title: string }[];
}

const Header: FC<IProps> = ({ setLang, lang, setLoader }) => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const home =
    location.pathname === "/" ||
    location.pathname === "/en" ||
    (location.pathname.indexOf("/brand") > -1 &&
      location.pathname.split("/").length === 3) ||
    (location.pathname.indexOf("/en/brand") > -1 &&
      location.pathname.split("/").length === 4);
  const [openGlobalMenu, setOpenGlobalMenu] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [menu, setMenu] = useState<IMenu[]>([]);
  const [categories, setCategories] = useState<ICategories[]>([]);
  const [id, setId] = useState("");
  const [activeCategories, setActiveCategories] = useState(false);

  const node = useRef<HTMLDivElement>(null);
  const globalBtn1 = useRef<any>(null);
  const globalBtn2 = useRef<any>(null);

  useEffect(() => {
    API.globals.getMenuData().then((res) => {
      setMenu(res.data);
    });
  }, [i18n.language]);

  useEffect(() => {
    setLoader(true);
    API.projects
      .getCategories()
      .then((res) => {
        setCategories(res?.data);
        setLoader(false);
      })
      .catch(() => setLoader(false));
  }, [i18n.language, setLoader]);

  useEffect(() => {
    if (location.pathname.indexOf("/brand") > -1) {
      setId(
        i18n.language === "en"
          ? location.pathname.split("/")[3]
          : location.pathname.split("/")[2]
      );
    } else {
      setId("");
    }
  }, [location, i18n.language]);

  useOnClickOutside(node, (e) => {
    if (
      !globalBtn1?.current?.contains(e.target) &&
      !globalBtn2?.current?.contains(e.target)
    ) {
      setOpenGlobalMenu(false);
    }
  });

  return (
    <HeaderContainer lang={lang} style={home ? { borderBottom: 0 } : {}}>
      <HeaderTop
        dark={true}
        setLang={setLang}
        openGlobalMenu={() => setOpenGlobalMenu(!openGlobalMenu)}
        node={globalBtn1}
      />

      <div className="container">
        <HeaderSearch
          dark={true}
          node={globalBtn2}
          openGlobalMenu={() => setOpenGlobalMenu(!openGlobalMenu)}
          openMenu={() => setOpenMenu(true)}
          categories={categories}
          activeCategories={activeCategories}
          setActiveCategories={setActiveCategories}
        />
      </div>

      <DesktopMenu
        menu={menu}
        activeCategories={activeCategories}
        closeCategories={() => setActiveCategories(false)}
      />

      {id && <BrandHeader id={id} home={home} />}

      <GlobalMenu
        node={node}
        open={openGlobalMenu}
        closeMenu={() => setOpenGlobalMenu(false)}
      />

      <MobileMenu
        categories={categories}
        open={openMenu}
        close={() => setOpenMenu(false)}
      />
    </HeaderContainer>
  );
};

export default Header;
