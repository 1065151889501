import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IHomeProject } from "types/home";
import { SubTitle } from "components/styled/SubTitle";
import HomeSingleOffer from "./homeOffer";
import DiscoverMore from "components/styled/DiscoverMore";
import { ReactComponent as ArrowLeft } from "assets/icons/CaretLeft.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { useIsTouchDevice } from "helpers";
import { Slider } from "components/styled/Slider";

interface IProps {
  offers: IHomeProject[];
  title?: string;
}

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade]);

const Offers: FC<IProps> = ({ offers, title }) => {
  const { t } = useTranslation();
  const touchDevice = useIsTouchDevice();
  const [screenSizes, setScreenSizes] = useState<number>(1);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (offers.length < 4) {
        setScreenSizes(offers.length);
      } else {
        if (screenWidth > 480 && screenWidth <= 743) {
          setScreenSizes(1.5);
        } else if (screenWidth >= 744 && screenWidth < 1024) {
          setScreenSizes(2.5);
        } else if (screenWidth >= 1024 && screenWidth < 1248) {
          setScreenSizes(3.5);
        } else if (screenWidth >= 1248) {
          setScreenSizes(4);
        } else if (screenWidth <= 480 && screenWidth > 320) {
          setScreenSizes(1.5);
        } else {
          setScreenSizes(1.2);
        }
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [offers.length]);

  return (
    <>
      <div className="container">
        <SubTitle>{title ? title : t("offers")}</SubTitle>
      </div>

      <Slider>
        <div className="slider-services">
          <div
            className={`container arrows ${touchDevice ? "" : "with-hover"}`}
          >
            <button className="offers-arrow-left arrow">
              <ArrowLeft />
            </button>
            <button className="offers-arrow-right arrow">
              <ArrowLeft />
            </button>
          </div>

          <Swiper
            navigation={{
              nextEl: ".offers-arrow-right",
              prevEl: ".offers-arrow-left",
            }}
            slidesPerView={screenSizes}
            freeMode={true}
            pagination={{ clickable: true, dynamicBullets: true }}
            initialSlide={0}
            className="offers-slider"
          >
            {offers.map((item, index) => (
              <SwiperSlide className="slider-item" key={`room-${index}`}>
                <HomeSingleOffer {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Slider>

      <div className="display-flex justify-center discover-more">
        <DiscoverMore url="/offers" />
      </div>
    </>
  );
};

export default Offers;
