import axiosInstance from "..";

const getCategories = () => {
  return axiosInstance.get("offers/getcategories").then((res) => {
    return res.data;
  });
};

const getOffers = (
  page: number,
  byCategorySlug: string,
  byBrandSlug?: string
) => {
  return axiosInstance
    .get("offers", {
      params: { page, byCategorySlug, byBrandSlug, per_page: 12 },
    })
    .then((res) => {
      return res.data;
    });
};

const getDetails = (slug: string) => {
  return axiosInstance.get("offers/show/" + slug).then((res) => {
    return res.data;
  });
};

const getOffersByCategory = (slug: string) => {
  return axiosInstance.get(`offers/${slug}`).then((res) => {
    return res.data;
  });
};

export const offers = {
  getCategories,
  getOffers,
  getDetails,
  getOffersByCategory,
};
