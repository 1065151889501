import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DocumentMeta from "react-document-meta";
import { ProjectTitle } from "consts/global";
import { API } from "services/API/API";
import { useCanonicalUrl } from "services/hooks/canonical";
import { IBlog, IOtherBlog } from "types/blog";
import { BG, SimilarNews, SingleBlogContainer } from "./styled";
import ListItem, { IListItem } from "components/parts/news";
import { Badge } from "components/styled/Badge";

const SingleBlog = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const [data, setData] = useState<IBlog>();
  const { id } = useParams();
  const [similarNews] = useState<IListItem[]>([]);
  const [prev, setPrev] = useState<IOtherBlog>();
  const [next, setNext] = useState<IOtherBlog>();
  const staticPage = location.pathname.indexOf("/static") > -1;

  useEffect(() => {
    if (staticPage && id) {
      API.globals.getStaticPageData(id).then((res) => {
        setData({
          ...res,
          "main-image": res.media,
        });
      });
    } else {
      if (id) {
        API.blogs.getBlogDetails(id).then((res) => {
          setData(res?.blog);
          setNext(res.next_blog);
          setPrev(res.prev_blog);
        });
      }
    }
  }, [id, i18n.language, staticPage]);

  const url = useCanonicalUrl();
  const meta = {
    canonical: url,
  };

  useEffect(() => {
    if (data?.title) {
      localStorage.setItem("page-category", t("blog"));
      localStorage.setItem("page-title", data?.meta_title || data?.title);
    }
  }, [data?.meta_title, data?.title, t]);

  return (
    <DocumentMeta
      {...meta}
      title={
        data?.meta_title
          ? `${data?.meta_title} | ${ProjectTitle}`
          : `${data?.title} | ${ProjectTitle}`
      }
      description={data?.meta_description}
    >
      <SingleBlogContainer>
        {!!data?.["main-image"]?.url && (
          <BG
            cover={
              (staticPage && id
                ? data?.["main-image"]?.url
                : data?.cover?.url) || ""
            }
          >
            <div className="content" />
            {/* <div className="overlow" /> */}
          </BG>
        )}

        <div className="container">
          {data?.category?.title && <Badge>{data?.category?.title}</Badge>}
          <h1 className="mb-400">{data?.title}</h1>
          <time>{data?.publish_date}</time>

          <p>{data?.short_description}</p>

          {data?.content.map((item) => (
            <p key={item.key}>
              {item.layout === "text" &&
                item.attributes.text !== undefined &&
                item.attributes.text !== null && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item.attributes.text
                        .replaceAll("<h1>", "<h2>")
                        .replaceAll("</h1>", "</h2>"),
                    }}
                  />
                )}

              {item.layout === "image" &&
                item.attributes.image !== undefined && (
                  <img src={item.attributes.image} alt={data?.title} />
                )}

              {item.layout === "image_text" &&
                item.attributes.text &&
                item.attributes.image !== undefined && (
                  <div className="float-left">
                    <img src={item.attributes.image} alt={data?.title} />
                    <p
                      dangerouslySetInnerHTML={{ __html: item.attributes.text }}
                    />
                  </div>
                )}

              {item.layout === "text_image" &&
                item.attributes.text &&
                item.attributes.image !== undefined && (
                  <div className="float-right">
                    <img src={item.attributes.image} alt={data?.title} />
                    <p
                      dangerouslySetInnerHTML={{ __html: item.attributes.text }}
                    />
                  </div>
                )}
            </p>
          ))}

          {(prev || next) && (
            <div className="siblings">
              {prev ? (
                <div>
                  <span>{t("prev")}</span>
                  <h4>
                    <a href={`./${prev.slug}`}>{prev.title}</a>
                  </h4>
                </div>
              ) : (
                <div></div>
              )}
              {next ? (
                <div>
                  <span>{t("next")}</span>
                  <h4>
                    <a href={`./${next?.slug}`}>{next?.title}</a>
                  </h4>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          )}
        </div>

        {!!similarNews.length && (
          <SimilarNews className="mt-1200">
            <h2 className="text-center">მსგავსი სიახლეები</h2>
            <div className="row">
              {similarNews.map((item, index) => (
                <div className="col-6" key={`news-${index}`}>
                  <ListItem withoutspace large inside {...item} />
                </div>
              ))}
            </div>
          </SimilarNews>
        )}
      </SingleBlogContainer>
    </DocumentMeta>
  );
};
export default SingleBlog;
