import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--Color-Border-Disabled, rgba(9, 30, 66, 0.06));
  position: relative;
  margin-top: 48px;
  min-height: 69px;

  a {
    margin: ${globalStyles.spaces[50]};
    width: 40px;
    height: 40px;
    background-color: ${(props) => props.theme.colorBackgroundNeutralSubtle};
    color: ${(props) => props.theme.colorTextPrimary};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    &.invisible {
      opacity: 0;
      touch-action: none;
      pointer-events: none;
    }

    &.left {
      position: absolute;
      right: 0px;
      width: auto;
      color: #fcb026 !important;
      gap: 0.5rem;
      background: transparent !important;

      &:first-child {
        left: 0;
        right: auto;
      }
    }

    &.active,
    &:hover {
      background-color: ${(props) => props.theme.colorPrimaryYellow};
      color: ${(props) => props.theme.colorTextInverse};
    }

    &:not(.left) {
      display: none;
    }

    @media (min-width: ${globalStyles.breackPoints.tablet}) {
      &:not(.left) {
        display: flex;
      }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.lgTablet}) {
    margin-top: 40px;
  }

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    margin-top: 24px;
  }
`;
