import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API } from "services/API/API";
import Input from "components/styled/Input";
import { Button } from "components/styled/Button";
import { ContactFormContainer } from "./styled";
import Dropdown from "components/styled/Dropdown";
import { useNavigate } from "react-router-dom";
import { currentLangUrl } from "services/hooks/canonical";

const required = ["full_name", "phone", "message"];

type FormData = {
  full_name: string;
  product_id: string;
  email: string;
  phone: string;
  message: string;
  country_code: string;
};

const initErrors = { full_name: false, message: false, phone: false };

const ContactForm = ({
  countryCodes,
  products,
}: {
  countryCodes: string[];
  products?: any[];
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    full_name: "",
    product_id: "",
    email: "",
    phone: "",
    message: "",
    country_code: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(initErrors);

  const changeText = (key: string, value: string) => {
    const formDataCopy = { ...formData };
    // @ts-ignore
    formDataCopy[key] = value;
    setFormData(formDataCopy);
  };

  const send = () => {
    if (
      required.some((i) => !formData[i as keyof FormData]) ||
      (formData.phone && formData.phone.length !== 9)
    ) {
      required.forEach((i) => {
        if (!formData[i as keyof FormData]) {
          setError((state) => ({ ...state, [i]: true }));
        } else {
          setError((state) => ({ ...state, [i]: false }));
        }
      });

      return false;
    } else {
      setError(initErrors);
    }

    setLoading(true);
    API.contact
      .send(formData)
      .then((res) => {
        // @ts-ignore
        if (res?.response?.data?.status === false) {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          for (const [field, messages] of Object.entries(
            // @ts-ignore
            res?.response?.data?.messages
          )) {
            // @ts-ignore
            messages.forEach((message) => {
              toast(message, { type: "error" });
            });
          }
        } else {
          setFormData({
            full_name: "",
            product_id: "",
            email: "",
            phone: "",
            message: "",
            country_code: countryCodes[0] || "",
          });
          toast(t("sentSuccessfully"), { type: "success" });
          navigate(currentLangUrl() + "/success");
          setError(initErrors);
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    setFormData((state) => ({ ...state, country_code: countryCodes[0] }));
  }, [countryCodes]);

  return (
    <ContactFormContainer>
      <ToastContainer />
      <h2 className="m-0">{t("sendMessage")}</h2>
      <div className="display-flex flex-column gap-200 mt-500 mb-500">
        <Input
          onChange={(e) => changeText("full_name", e.target.value)}
          value={formData.full_name}
          transparent
          placeholder={t("nameAndSurname")}
          hasError={error.full_name && !formData.full_name}
          name="full_name"
        />

        {products ? (
          <Dropdown
            items={products}
            label={t("chooseProduct")}
            onSelect={(e) => changeText("product_id", e)}
            value={formData.product_id}
          />
        ) : (
          <Input
            onChange={(e) => changeText("email", e.target.value)}
            value={formData.email}
            transparent
            placeholder={t("emailShort")}
          />
        )}

        <Input
          onChange={(e) => changeText("phone", e.target.value)}
          value={formData.phone}
          transparent
          placeholder={t("phone")}
          type="number"
          mobile
          codes={countryCodes.map((i) => ({ value: i, label: i }))}
          handleDropdownSelect={(value) => changeText("country_code", value)}
          hasError={
            error.phone &&
            (!formData.phone ||
              !!(formData.phone && formData.phone.length !== 9))
          }
          name="phone"
        />

        <Input
          onChange={(e) => changeText("message", e.target.value)}
          value={formData.message}
          transparent
          placeholder={t("message")}
          type="textarea"
          hasError={error.message && !formData.message}
          name="message"
        />
      </div>
      <Button
        variant="primary"
        onClick={() => send()}
        size="lg"
        disabled={loading}
      >
        {t("send")}
      </Button>
    </ContactFormContainer>
  );
};

export default ContactForm;
