import axiosInstance from "..";

const getCategories = () => {
  return axiosInstance.get("blogs/getcategories").then((res) => {
    return res.data;
  });
};

const getBlogs = (
  page: number,
  byCategorySlug?: string,
  byBrandSlug?: string
) => {
  return axiosInstance
    .get("blogs", {
      params: { page, byCategorySlug, byBrandSlug, per_page: 12 },
    })
    .then((res) => {
      return res.data;
    });
};

const getBlogDetails = (blogSlug: string) => {
  return axiosInstance.get(`/blogs/show/${blogSlug}`).then((res) => {
    return res.data;
  });
};

export const blogs = {
  getCategories,
  getBlogs,
  getBlogDetails,
};
