import { FC, useState, useEffect } from "react";
import { BrandHeader } from "./styled";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { API } from "services/API/API";
import { currentLangUrl } from "services/hooks/canonical";

interface IBrandHeader {
  home: boolean;
  id: string;
}

const Brand: FC<IBrandHeader> = ({ home, id }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [logo, setLogo] = useState<string>();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (id) {
      API.brand.getBrandLogo(id).then((res) => {
        setLogo(res?.url);
      });
    }
  }, [id]);

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showMenu]);

  return (
    <BrandHeader home={home} transparent={home && !showMenu} open={showMenu}>
      <div className="container brand-container">
        <div className="display-flex align-items-center">
          <Link to={currentLangUrl() + `/brand/${id}`} className="list-item">
            {logo && (
              <img src={logo} alt={id} height={24} className="brand-logo" />
            )}
          </Link>
        </div>

        {/* <div className="list">
          <Link
            to={currentLangUrl() + `/brand/${id}/products`}
            className="list-item"
          >
            {t("products")}
          </Link>
          <Link
            to={currentLangUrl() + `/brand/${id}/offers`}
            className="list-item"
          >
            {t("offers")}
          </Link>
          <Link
            to={currentLangUrl() + `/brand/${id}/service`}
            className="list-item"
          >
            {t("services")}
          </Link>
        </div>

        <div
          className="icon-container show-md"
          onClick={() => setShowMenu(!showMenu)}
        >
          {showMenu ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19.2806 18.2198C19.3502 18.2895 19.4055 18.3722 19.4432 18.4632C19.4809 18.5543 19.5003 18.6519 19.5003 18.7504C19.5003 18.849 19.4809 18.9465 19.4432 19.0376C19.4055 19.1286 19.3502 19.2114 19.2806 19.281C19.2109 19.3507 19.1281 19.406 19.0371 19.4437C18.9461 19.4814 18.8485 19.5008 18.7499 19.5008C18.6514 19.5008 18.5538 19.4814 18.4628 19.4437C18.3717 19.406 18.289 19.3507 18.2193 19.281L11.9999 13.0607L5.78055 19.281C5.63982 19.4218 5.44895 19.5008 5.24993 19.5008C5.05091 19.5008 4.86003 19.4218 4.7193 19.281C4.57857 19.1403 4.49951 18.9494 4.49951 18.7504C4.49951 18.5514 4.57857 18.3605 4.7193 18.2198L10.9396 12.0004L4.7193 5.78104C4.57857 5.64031 4.49951 5.44944 4.49951 5.25042C4.49951 5.05139 4.57857 4.86052 4.7193 4.71979C4.86003 4.57906 5.05091 4.5 5.24993 4.5C5.44895 4.5 5.63982 4.57906 5.78055 4.71979L11.9999 10.9401L18.2193 4.71979C18.36 4.57906 18.5509 4.5 18.7499 4.5C18.949 4.5 19.1398 4.57906 19.2806 4.71979C19.4213 4.86052 19.5003 5.05139 19.5003 5.25042C19.5003 5.44944 19.4213 5.64031 19.2806 5.78104L13.0602 12.0004L19.2806 18.2198Z"
                fill="#C7D1DB"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21 12C21 12.1989 20.921 12.3897 20.7803 12.5303C20.6397 12.671 20.4489 12.75 20.25 12.75H3.75C3.55109 12.75 3.36032 12.671 3.21967 12.5303C3.07902 12.3897 3 12.1989 3 12C3 11.8011 3.07902 11.6103 3.21967 11.4697C3.36032 11.329 3.55109 11.25 3.75 11.25H20.25C20.4489 11.25 20.6397 11.329 20.7803 11.4697C20.921 11.6103 21 11.8011 21 12ZM3.75 6.75H20.25C20.4489 6.75 20.6397 6.67098 20.7803 6.53033C20.921 6.38968 21 6.19891 21 6C21 5.80109 20.921 5.61032 20.7803 5.46967C20.6397 5.32902 20.4489 5.25 20.25 5.25H3.75C3.55109 5.25 3.36032 5.32902 3.21967 5.46967C3.07902 5.61032 3 5.80109 3 6C3 6.19891 3.07902 6.38968 3.21967 6.53033C3.36032 6.67098 3.55109 6.75 3.75 6.75ZM20.25 17.25H3.75C3.55109 17.25 3.36032 17.329 3.21967 17.4697C3.07902 17.6103 3 17.8011 3 18C3 18.1989 3.07902 18.3897 3.21967 18.5303C3.36032 18.671 3.55109 18.75 3.75 18.75H20.25C20.4489 18.75 20.6397 18.671 20.7803 18.5303C20.921 18.3897 21 18.1989 21 18C21 17.8011 20.921 17.6103 20.7803 17.4697C20.6397 17.329 20.4489 17.25 20.25 17.25Z"
                fill="#C7D1DB"
              />
            </svg>
          )}
        </div> */}
      </div>

      {showMenu && (
        <div className="brand-menu-body">
          <Link
            to={currentLangUrl() + `/brand/${id}/products`}
            className={`list-item ${location.pathname.indexOf(`/brand/${id}/products`) > -1 ? "active" : ""}`}
            onClick={() => setShowMenu(false)}
          >
            {t("products")}
          </Link>
          <Link
            to={currentLangUrl() + `/brand/${id}/offers`}
            className={`list-item ${location.pathname.indexOf(`/brand/${id}/offers`) > -1 ? "active" : ""}`}
            onClick={() => setShowMenu(false)}
          >
            {t("offers")}
          </Link>
          <Link
            to={currentLangUrl() + `/brand/${id}/service`}
            className={`list-item ${location.pathname.indexOf(`/brand/${id}/service`) > -1 ? "active" : ""}`}
            onClick={() => setShowMenu(false)}
          >
            {t("services")}
          </Link>
        </div>
      )}
    </BrandHeader>
  );
};

export default Brand;
