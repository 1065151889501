import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const BrandLayout = styled.div`
  .brands-box-list {
    display: flex;
    gap: 1.5rem;

    & > div {
      flex: 1;
    }

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      flex-wrap: wrap;

      & > div {
        min-width: 45dvw;
      }
    }
    & > div {
      min-width: calc(calc(100% - 4.5rem) / 4);
      flex: 1;
    }

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      flex-wrap: wrap;

      & > div {
        min-width: calc(calc(100% - 1.5rem) / 2);
      }
    }

    .brand-info {
      @media (max-width: ${globalStyles.breackPoints.tablet}) {
        padding-top: 48px;
      }

      h3 {
        color: ${(props) => props.theme.colorTextPrimary};
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        margin: 0;

        @media (max-width: ${globalStyles.breackPoints.md}) {
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 8px;
        }

        @media (max-width: ${globalStyles.breackPoints.tablet}) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      p {
        margin: ${globalStyles.spaces[400]} 0;
        color: ${(props) => props.theme.colorTextPrimary};

        @media (max-width: ${globalStyles.breackPoints.tablet}) {
          margin: ${globalStyles.spaces[200]} 0;
        }
      }
    }

    .brand-products {
      @media (max-width: ${globalStyles.breackPoints.tablet}) {
        padding-top: 0;
        padding-bottom: 40px;

        h2 {
          font-size: 20px;
          line-height: 28px;
          text-align: left;
        }
      }
    }
  }

  .parallax-content {
    height: 647px;

    @media (max-width: ${globalStyles.breackPoints.lg}) {
      height: 450px;
    }

    @media (max-width: ${globalStyles.breackPoints.md}) {
      height: 380px;
    }

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      height: 530px;
    }
  }

  .brand-info {
    h3 {
      color: ${(props) => props.theme.colorTextPrimary};
      font-size: 40px;
      font-weight: 700;
      line-height: 48px;
      margin: 0;
    }

    p {
      margin: ${globalStyles.spaces[400]} 0;
      color: ${(props) => props.theme.colorTextPrimary};
    }

    blockquote {
      font-size: 24px;
      line-height: 32px;
    }

    @media (max-width: 1024px) {
      h3 {
        font-size: 32px;
        line-height: 40px;
      }

      blockquote {
        font-size: 20px;
        line-height: 28px;
      }
    }

    @media (max-width: 743px) {
      h3 {
        font-size: 24px;
        line-height: 32px;
      }

      blockquote {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .pt-1000 {
    padding-top: 80px;
  }

  .pb-1000 {
    padding-bottom: 80px;
  }

  @media (max-width: 1247px) {
    .pt-1000 {
      padding-top: 64px;
    }

    .pb-1000 {
      padding-bottom: 64px;
    }
  }

  @media (max-width: 1023px) {
    .pt-1000 {
      padding-top: 56px;
    }

    .pb-1000 {
      padding-bottom: 56px;
    }
  }

  @media (max-width: 743px) {
    .pt-1000 {
      padding-top: 32px;
    }

    .pb-1000 {
      padding-bottom: 32px;
    }
  }
`;
