export const navs = [
  { title: "offers", link: "/offers" },
  {
    title: "blog",
    link: "/blog",
  },
  {
    title: "vlog",
    link: "/vlog",
  },
  // {
  //   title: "servicesAndParts",
  //   link: "/service",
  // },
  {
    title: "aboutUs",
    link: "/about",
  },
  // {
  //   title: "serviceReservation",
  //   link: "reserveService",
  // },
  {
    title: "contact",
    link: "/contact",
  },
  // {
  //   title: "leasing",
  //   link: "/leasing",
  // },
];
