import axiosInstance from "..";

const getMenuData = () => {
  return axiosInstance.get("menudata").then((res) => {
    return res.data;
  });
};

const getStaticpage = () => {
  return axiosInstance.get("staticpage").then((res) => {
    return res.data;
  });
};

const getStaticPageData = (slug: string) => {
  return axiosInstance.get(`staticpage/show/${slug}`).then((res) => {
    return res.data?.data;
  });
};

const getExternalmenu = () => {
  return axiosInstance.get("externalmenu").then((res) => {
    return res.data;
  });
};

export const globals = {
  getMenuData,
  getStaticpage,
  getStaticPageData,
  getExternalmenu,
};
