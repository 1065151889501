import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IHomeVlog } from "types/home";
import { removeHtmlTags, useIsTouchDevice } from "helpers";
import { SubTitle } from "components/styled/SubTitle";
import DiscoverMore from "components/styled/DiscoverMore";
import ListItem, { IListItem } from "../news";
import VideoSlider from "../videoSlider";
import { ReactComponent as ArrowLeft } from "assets/icons/CaretLeft.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { Slider } from "components/styled/Slider";

interface IProps {
  vlogs: IHomeVlog[];
}

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade]);

const HomeVideos: FC<IProps> = ({ vlogs }) => {
  const { t } = useTranslation();
  const touchDevice = useIsTouchDevice();
  const [data, setData] = useState<IListItem[]>([]);
  const [openVideoSlider, setOpenVideoSlider] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (vlogs && vlogs.length > 0) {
      const arrayOfObjects = vlogs.map((item) => {
        return {
          title: item.title,
          img: item.media?.url,
          description: removeHtmlTags(item.description),
          url: "",
          youtubeUrl: item?.youtubeUrl,
        };
      });
      setData(arrayOfObjects);
    }
  }, [vlogs]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 744) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <VideoSlider
        imgs={vlogs.map((item) => (item.videoUrl ? item.videoUrl : item.img))}
        open={openVideoSlider}
        index={index}
        onClose={() => setOpenVideoSlider(false)}
      />

      <div className="container">
        <SubTitle className="home-vlog-title" center={!mobile}>
          {t("vlog")}
        </SubTitle>
      </div>

      {mobile ? (
        <Slider>
          <div className="slider-services">
            <div
              className={`container arrows ${touchDevice ? "" : "with-hover"} vlog`}
            >
              <button className="vlog-arrow-left arrow">
                <ArrowLeft />
              </button>
              <button className="vlog-arrow-right arrow">
                <ArrowLeft />
              </button>
            </div>

            <Swiper
              navigation={{
                nextEl: ".vlog-arrow-right",
                prevEl: ".vlog-arrow-left",
              }}
              slidesPerView={1}
              freeMode={true}
              pagination={{ clickable: true, dynamicBullets: true }}
              initialSlide={0}
              className="vlog-slider"
            >
              {data.map((item, index) => (
                <SwiperSlide className="slider-item" key={`room-${index}`}>
                  <ListItem
                    video
                    withoutspace
                    superSmallImg
                    {...item}
                    blogMax
                    maxDescription
                    onOpen={() => {
                      setOpenVideoSlider(true);
                      setIndex(index);
                    }}
                    style={{ margin: "0 16px" }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Slider>
      ) : (
        <div className="container row mobile-slide-list">
          {data.map((item, index) => (
            <div key={item.title + index} className="col-3 col-md-6 col-xs-12">
              <ListItem
                video
                withoutspace
                superSmallImg
                {...item}
                blogMax
                maxDescription
                onOpen={() => {
                  setOpenVideoSlider(true);
                  setIndex(index);
                }}
              />
            </div>
          ))}
        </div>
      )}

      <div className="display-flex justify-center mt-300">
        <DiscoverMore url="/vlog" />
      </div>
    </>
  );
};

export default HomeVideos;
