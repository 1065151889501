import { globalStyles } from "consts/spaces";
import styled from "styled-components";
import Blockquote from "assets/icons/blockquote.svg";

export const HomePageContainer = styled.div`
  margin: 0 auto;

  .grey-bg {
    background: var(--Color-Background-Input_hovered, #f7f8f9);

    .slick-slider {
      button:hover {
        // to do
        background-color: #dcdfe4;
      }
    }
  }

  blockquote {
    font-size: ${globalStyles.fonts.h4};
    background-image: url(${Blockquote});
    padding-top: 33px;
    background-repeat: no-repeat;
    background-position: ${globalStyles.spaces[300]} 0;
    margin: ${globalStyles.spaces[400]} 0;
    padding-left: ${globalStyles.spaces[300]};
    border-left: 4px solid
      ${(props) => props.theme.colorBorderNeutralBolder_hovered};
  }

  .seeMoreButton {
    margin: ${globalStyles.spaces[400]} auto;
    display: flex;
    gap: ${globalStyles.spaces[100]};
    align-items: center;
    color: ${(props) => props.theme.colorPrimaryYellow};

    svg {
      transform: rotate(-90deg);
    }
  }

  .home-blogs {
    .col-3 {
      max-width: calc(calc(100% - 72px) / 4);

      @media (max-width: ${globalStyles.breackPoints.md}) {
        max-width: calc(calc(100% - 24px) / 2);
      }

      @media (max-width: ${globalStyles.breackPoints.tablet}) {
        max-width: 100%;
      }
    }
  }

  .home-vlog-title {
    margin-bottom: 32px;

    @media (max-width: 1024px) {
      margin-bottom: 24px;
    }

    @media (max-width: 743px) {
      margin-bottom: 20px;
    }
  }

  .mb-1000 {
    margin-bottom: 80px;
  }

  .mt-1000 {
    margin-top: 80px;
  }

  .pb-1000 {
    padding-bottom: 80px;
  }

  .pt-1000 {
    padding-top: 80px;
  }

  h2 {
    margin-bottom: 24px;

    &.lg-space {
      margin-bottom: 48px;
    }
  }

  @media (max-width: 1919px) {
    .pt-1000 {
      padding-top: 72px;
    }
    .pb-1000 {
      padding-bottom: 72px;
    }
    .mt-1000 {
      margin-top: 72px;
    }
    .mb-1000 {
      margin-bottom: 72px;
    }
  }

  @media (max-width: 1024px) {
    .pt-1000 {
      padding-top: 56px;
    }
    .pb-1000 {
      padding-bottom: 56px;
    }
    .mt-1000 {
      margin-top: 56px;
    }
    .mb-1000 {
      margin-bottom: 56px;
    }
    h2 {
      margin-bottom: 20px;

      &.lg-space {
        margin-bottom: 24px;
      }
    }
  }

  @media (max-width: 743px) {
    .pt-1000 {
      padding-top: 48px;
    }
    .pb-1000 {
      padding-bottom: 48px;
    }
    .mt-1000 {
      margin-top: 48px;
    }
    .mb-1000 {
      margin-bottom: 48px;
    }
  }

  @media (max-width: 479px) {
    .pt-1000 {
      padding-top: 40px;
    }
    .pb-1000 {
      padding-bottom: 40px;
    }
    .mt-1000 {
      margin-top: 40px;
    }
    .mb-1000 {
      margin-bottom: 40px;
    }
  }

  .discover-more {
    margin-top: 40px;

    @media (max-width: 1247px) {
      margin-top: 32px;
    }

    @media (max-width: 743px) {
      margin-top: 20px;
    }

    @media (max-width: 479px) {
      margin-top: 24px;
    }
  }
`;

export const SliderContainer = styled.div`
  width: 100%;
  position: relative;

  .slick-list {
    overflow: visible;
  }

  .slick-slider {
    button {
      top: -64px;
      right: 16px;
      left: auto;
      width: 48px;
      height: 48px;
      padding: 8px;
      transform: rotate(180deg);
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 2;

      &:before {
        display: none;
      }

      &.slick-prev {
        right: 72px;
        transform: rotate(0deg);
      }

      &.slick-next:disabled,
      &.slick-next[disabled],
      &.slick-prev:disabled,
      &.slick-prev[disabled] {
        background: #ffffff00;

        svg path {
          fill: #091e424f;
        }
      }

      @media (hover: hover) {
        &:hover {
          background-color: #f9f9f8;
        }
      }
    }
  }

  &.without-slider {
    .slick-track {
      margin-left: inherit;
    }

    .slick-slider {
      button {
        display: none !important;
      }
    }
  }
`;
