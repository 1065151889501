import { FC, useEffect, useRef, useState } from "react";
import { BlogCardContainer } from "./styled";
import { Link } from "react-router-dom";
import { currentLangUrl } from "services/hooks/canonical";

interface IProps {
  title: string;
  url: string;
  description: string;
  img: string;
  isSmall?: boolean;
  brandNews?: boolean;
}

const BlogCard: FC<IProps> = ({
  title,
  url,
  description,
  img,
  isSmall,
  brandNews,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [imgWidth, setImgWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        const updateWidth = () => {
          if (imgRef.current) {
            setImgWidth(imgRef?.current?.offsetWidth);
          }
        };

        updateWidth();

        window.addEventListener("resize", updateWidth);

        return () => {
          window.removeEventListener("resize", updateWidth);
        };
      }, 0);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [imgRef?.current?.offsetWidth]);

  return (
    <Link to={currentLangUrl() + "/blog/" + url}>
      <BlogCardContainer className={isSmall ? "isSmall" : ""}>
        <div
          className="img-container"
          style={brandNews ? { height: imgWidth / 1.928 } : {}}
        >
          <img ref={imgRef} src={img} alt={title} />
        </div>
        <div className="description">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </BlogCardContainer>
    </Link>
  );
};

export default BlogCard;
