import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const AboutContainer = styled.div`
  margin-top: 80px;

  & > div {
    max-width: 100%;

    img {
      max-height: 496px;
      max-height: 100%;
      height: 100%;
    }
  }

  .img {
    background-color: lightgray;
    height: 496px;

    @media (max-width: ${globalStyles.breackPoints.md}) {
      height: 448px;
    }

    @media (max-width: 1024px) {
      height: 394px;
    }

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      height: 282px;
    }

    @media (max-width: ${globalStyles.breackPoints.mobile}) {
      height: 177px;
    }
  }

  ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    column-gap: 20px;

    p {
      margin: 0;
    }

    li {
      padding-bottom: 0.5em;
      margin-bottom: 0.5em;
      border-bottom: 1px solid rgb(23, 43, 77);
    }

    @media (max-width: 1024px) {
      column: auto;
      -webkit-columns: auto;
    }
  }

  @media (max-width: 1247px) {
    margin-top: 48px;
  }

  @media (max-width: 743px) {
    margin-top: 32px;
  }
`;
