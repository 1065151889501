import axiosInstance from "..";

const getLeasingData = () => {
  return axiosInstance.get("leasing").then((res) => {
    return res.data;
  });
};

const storeLeasing = (data: {
  full_name: string;
  phone: string;
  agree_news: boolean;
  product_id?: number;
}) => {
  return axiosInstance.post("leasing/store", data).then((res) => {
    return res.data;
  });
};

export const leasing = {
  getLeasingData,
  storeLeasing,
};
