import { globalStyles } from "consts/spaces";
import { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { currentLangUrl } from "services/hooks/canonical";
import styled from "styled-components";
import { IHomeProject } from "types/home";

const HomeRoomContainer = styled.div<{ withoutspace: boolean }>`
  position: relative;
  margin: ${(props) => (props.withoutspace ? "0" : "0 16px")};

  div {
    width: 100%;
    object-fit: cover;
    display: block;
    height: 340px;
    border-radius: 8px;
    background-size: cover !important;
  }

  img {
    border-radius: 8px;
    height: 284px;
    width: 100%;
    object-fit: cover;
  }

  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: ${(props) => props.theme.colorTextPrimary};
    margin: ${globalStyles.spaces[200]} 0 ${globalStyles.spaces[150]} 0;

    overflow: hidden;
    display: -webkit-box;   
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #44546F;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;   
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .cover {
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    height: 284px;
    rgba(211, 211, 211, 0.5);
  }

  &:hover {
    .cover {
      opacity: 0.9;
    }
  }
`;

const HomeSingleOffer: FC<Partial<IHomeProject>> = (props) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [imgWidth, setImgWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        const updateWidth = () => {
          if (imgRef.current) {
            setImgWidth(imgRef?.current?.offsetWidth);
          }
        };

        updateWidth();

        window.addEventListener("resize", updateWidth);

        return () => {
          window.removeEventListener("resize", updateWidth);
        };
      }, 0);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [imgRef?.current?.offsetWidth]);

  return (
    <HomeRoomContainer withoutspace={props.withoutspace || false}>
      <Link to={currentLangUrl() + "/offers/" + props.slug}>
        <div className="cover" style={{ height: imgWidth / 1.33 }} />
        <img
          ref={imgRef}
          src={props.img}
          alt={props.title}
          style={{ height: imgWidth / 1.33 }}
        />

        <h3>{props.title}</h3>
        <p>{props?.short_description?.slice(0, 100)}</p>
      </Link>
    </HomeRoomContainer>
  );
};

export default HomeSingleOffer;
