import { FC, memo } from "react";
import { Routes, Route } from "react-router-dom";

import Homepage from "pages/Homepage";
import Blog from "pages/Blog";
import Contact from "pages/Contact";
import Projects from "pages/Projects";
import Offers from "pages/Offers";
import SingleBlog from "pages/Blog/single";
import SingleProject from "pages/Projects/single";
import SingleOffer from "pages/Offers/single";
import About from "pages/about";
import Search from "pages/search";
import NotFound from "pages/404";
import Vlog from "pages/Vlog";
// import Service from "pages/Service";
import BrandLanding from "pages/Brand";
// import Leasing from "pages/Leasing";
import SuccessPage from "pages/Success";

interface IProps {
  lang?: string;
}

const langs = ["/", "/en"];

const Router: FC<IProps> = ({ lang }) => {
  return (
    <Routes data-lang={lang}>
      {langs.map((item) => (
        <Route path={item} key={item}>
          <Route index element={<Homepage />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:id" element={<SingleBlog />} />
          <Route path="contact" element={<Contact />} />

          <Route path="products" element={<Projects />} />
          <Route path="products/:id" element={<Projects />} />
          {/* <Route path="used" element={<Projects />} /> */}
          <Route path="product/:id" element={<SingleProject />} />

          <Route path="vlog" element={<Vlog />} />

          <Route path="offers" element={<Offers />} />
          <Route path="offers/:id" element={<SingleOffer />} />
          <Route path="about" element={<About />} />
          <Route path="search" element={<Search />} />
          {/* <Route path="service" element={<Service />} /> */}

          {/* <Route path="leasing" element={<Leasing />} /> */}
          <Route path="success" element={<SuccessPage />} />

          <Route path="brand/:id" element={<BrandLanding />} />
          <Route path="brand/:id/blog" element={<Blog />} />
          <Route path="brand/:brandId/blog/:id" element={<SingleBlog />} />
          <Route path="brand/:id/offers" element={<Offers />} />
          <Route path="brand/:brandId/products" element={<Projects />} />
          <Route path="brand/:brandId/products/:id" element={<Projects />} />
          {/* <Route path="brand/:id/service" element={<Service />} /> */}

          <Route path="static/:id" element={<SingleBlog />} />
        </Route>
      ))}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default memo(Router);
