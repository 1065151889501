import { useEffect, useState } from "react";
import { API } from "services/API/API";
import { useParams } from "react-router-dom";
import { IBlog } from "types/blog";
import { useCanonicalUrl } from "services/hooks/canonical";
import DocumentMeta from "react-document-meta";
import { SingleBlogContainer } from "pages/Blog/styled";
import { useTranslation } from "react-i18next";
import { ProjectTitle } from "consts/global";

const SingleOffer = () => {
  const { i18n, t } = useTranslation();
  const [data, setData] = useState<IBlog>();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      API.offers.getDetails(id).then((res) => {
        setData(res);
        localStorage.setItem("page-category", t("offers"));
        localStorage.setItem("page-title", res?.meta_title || res?.title);
      });
    }
  }, [id, i18n.language, t]);

  const url = useCanonicalUrl();
  const meta = {
    canonical: url,
  };

  return (
    <DocumentMeta
      {...meta}
      title={
        data?.meta_title
          ? `${data?.meta_title} | ${ProjectTitle}`
          : `${data?.title} | ${ProjectTitle}`
      }
      description={data?.meta_description}
    >
      <SingleBlogContainer className="container offers">
        <h1 className="mb-400">{data?.title}</h1>
        {/* {data?.short_description && (
          <p
            className="mb-400"
            dangerouslySetInnerHTML={{ __html: data?.short_description }}
          />
        )}
        {!!data?.["main-image"]?.url && (
          <img src={data?.["main-image"]?.url} alt="Tegeta" />
        )} */}

        {data?.content.map((item) => (
          <>
            {item.layout === "text" && item.attributes.text !== undefined && (
              <p
                key={item.key}
                dangerouslySetInnerHTML={{
                  __html: item.attributes.text
                    .replaceAll("<h1>", "<h2>")
                    .replaceAll("</h1>", "</h2>"),
                }}
              />
            )}

            {item.layout === "image" && item.attributes.image !== undefined && (
              <img
                src={item.attributes.image}
                alt={data?.title}
                key={item.key}
              />
            )}

            {item.layout === "image_text" &&
              item.attributes.text &&
              item.attributes.image !== undefined && (
                <div className="float-left" key={item.key}>
                  <img src={item.attributes.image} alt={data?.title} />
                  <p
                    dangerouslySetInnerHTML={{ __html: item.attributes.text }}
                  />
                </div>
              )}

            {item.layout === "text_image" &&
              item.attributes.text &&
              item.attributes.image !== undefined && (
                <div className="float-right" key={item.key}>
                  <img src={item.attributes.image} alt={data?.title} />
                  <p
                    dangerouslySetInnerHTML={{ __html: item.attributes.text }}
                  />
                </div>
              )}
          </>
        ))}
      </SingleBlogContainer>
    </DocumentMeta>
  );
};
export default SingleOffer;
