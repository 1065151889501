import { globalStyles } from "consts/spaces";
import styled from "styled-components";
import Blockquote from "../../assets/icons/blockquote.svg";

export const BlogPageContainer = styled.div`
  margin: 0 auto;
`;

export const BlogList = styled.div`
  display: flex;
  flex-wrap: wrap;

  .col-3,
  .col-lg-4,
  .col-xs-12,
  .col-sm-6 {
    overflow: hidden;
    word-wrap: break-word;
  }

  a > img {
    height: 177px;

    @media (min-width: ${globalStyles.breackPoints.mobile}) {
      height: 275px;
    }

    @media (min-width: ${globalStyles.breackPoints.tablet}) {
      height: 437px;
    }

    @media (min-width: ${globalStyles.breackPoints.md}) {
      height: 297px;
    }

    @media (min-width: ${globalStyles.breackPoints.lg}) {
      height: 232px;
    }
  }
`;

export const SingleBlogContainer = styled.div`
  color: ${(props) => props.theme.colorTextPrimary};

  .container {
    margin-top: 80px;
    margin-bottom: 80px;

    @media (max-width: 1919px) {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    @media (max-width: 1247px) {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    @media (max-width: 743px) {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
  .siblings {
    display: flex;
    margin: ${globalStyles.spaces[700]} 0;
    border-top: 1px solid rgba(9, 30, 66, 0.14);
    border-bottom: 1px solid rgba(9, 30, 66, 0.14);

    div {
      flex: 1;
      width: 50%;
      padding: ${globalStyles.spaces[300]} ${globalStyles.spaces[400]};

      a {
        color: ${(props) => props.theme.colorTextPrimary};
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      span {
        text-align: right;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        display: block;
        margin-bottom: 8px;
        color: ${(props) => props.theme.colorTextSubtlest};
      }

      h4 {
        color: ${(props) => props.theme.colorTextPrimary};
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        text-align: right;
        margin: 0;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        @media (max-width: 1024px) {
          font-size: 16px;
          line-height: 24px;
        }

        @media (max-width: 743px) {
          font-size: 14px;
          line-height: 22px;
        }
      }

      &:nth-child(2) {
        border-left: 1px solid rgba(9, 30, 66, 0.14);
        span,
        h4 {
          text-align: left;
        }
      }

      @media (max-width: ${globalStyles.breackPoints.md}) {
        padding: 20px;
      }
    }
  }

  time {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  img {
    width: 100%;
    max-height: 496px;
    object-fit: cover;
  }

  &.offers img {
    object-fit: contain;
  }

  h1 {
    margin: ${globalStyles.spaces[100]} 0;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    color: ${(props) => props.theme.colorTextPrimary};

    @media (max-width: 1024px) {
      font-size: 32px;
      line-height: 40px;
    }

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      font-size: ${globalStyles.fonts.h3};
    }
  }

  & > .row {
    @media (max-width: ${globalStyles.breackPoints.md}) {
      flex-direction: column-reverse;
      gap: ${globalStyles.spaces[100]};
      margin-bottom: ${globalStyles.spaces[200]};

      img {
        height: auto;
      }
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: ${globalStyles.spaces[400]} 0;

    @media (max-width: ${globalStyles.breackPoints.md}) {
      margin: ${globalStyles.spaces[200]} 0;
    }

    &.date {
      margin: 0;
      color: ${(props) => props.theme.colorTextSubtlest};
    }

    &.slide-desc {
      margin: ${globalStyles.spaces[200]} 0 0 0;
    }
  }

  h2 {
    margin: ${globalStyles.spaces[400]} 0;
    font-style: normal;
    font-weight: 700;

    @media (max-width: ${globalStyles.breackPoints.md}) {
      font-size: ${globalStyles.fonts.h4};
      margin: ${globalStyles.spaces[200]} 0;
    }
  }

  blockquote {
    font-size: ${globalStyles.fonts.h4};
    background-image: url(${Blockquote});
    padding-top: 33px;
    background-repeat: no-repeat;
    background-position: ${globalStyles.spaces[300]} 0;
    margin: ${globalStyles.spaces[400]} 0;
    padding-left: ${globalStyles.spaces[300]};
    border-left: 4px solid
      ${(props) => props.theme.colorBorderNeutralBolder_hovered};
  }

  .float-left {
    // display: flex;

    img {
      max-width: 220px;
      margin-right: ${globalStyles.spaces[300]};
      margin-bottom: ${globalStyles.spaces[300]};
      float: left;
    }

    p {
      margin: ${globalStyles.spaces[300]} 0;
    }

    &:after {
      content: "";
      clear: both;
      display: block;
    }

    @media (max-width: 743px) {
      display: flex;
      flex-direction: column;

      img {
        max-width: unset;
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }

  .float-right {
    // display: flex;
    // justify-content: space-between;

    img {
      float: right;
      max-width: 220px;
      margin-left: ${globalStyles.spaces[300]};
      margin-bottom: ${globalStyles.spaces[300]};
    }

    p {
      margin: ${globalStyles.spaces[300]} 0;
    }

    &:after {
      content: "";
      clear: both;
      display: block;
    }

    @media (max-width: 743px) {
      display: flex;
      flex-direction: column-reverse;

      img {
        max-width: unset;
        margin-left: 0;
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    .mb-t-400 {
      margin-top: ${globalStyles.spaces[200]};
    }
  }

  &.offers {
    margin-top: 80px;

    @media (max-width: 1247px) {
      margin-top: 48px;
    }

    @media (max-width: 743px) {
      margin-top: 32px;
    }
  }
`;

export const SimilarNews = styled.div``;

export const BG = styled.div<{ cover: string }>`
  position: relative;
  overflow: hidden;

  .content {
    height: 580px;
    background-image: url("${(props) => props.cover}");
    // background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .overlow {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(211, 211, 211, 0.5);
  }

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    .content {
      height: 401px;
    }
  }

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    .content {
      height: 252px;
    }
  }
`;
