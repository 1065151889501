import { FC, InputHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { StyledContainer, StyledInput, StyledTextarea } from "./styled.input";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  transparent?: boolean;
  borderinverse?: boolean;
  borderBottom?: boolean;
  color?: string;
  icon?: string;
  preIcon?: string;
  type?: string;
  white?: boolean;
  hasError?: boolean;
  mobile?: boolean | number;
  codes?: { value: string; label: string }[];
  handleDropdownSelect?: (selectedValue: string) => void;
  required?: boolean;
}

const Input: FC<InputProps> = (props) => {
  const { i18n } = useTranslation();

  return (
    <StyledContainer
      mobile={props.mobile ? 1 : 0}
      value={props.value?.toString() || ""}
      lang={i18n.language === "en" ? "en" : "ka"}
    >
      {props.preIcon ? (
        <img className="preIcon" src={props.preIcon} alt="input-icon" />
      ) : null}
      {props.type && props.type === "textarea" ? (
        <>
          <StyledTextarea {...props} />
          {props.placeholder && !props.value && !props.hasError && (
            <span className="placeholder">{props.placeholder}</span>
          )}
        </>
      ) : (
        <StyledInput {...props} mobile={props.mobile ? 1 : 0} />
      )}

      {props.mobile ? (
        <div className="mobile">
          <div>+995</div>
        </div>
      ) : null}

      {props.icon ? (
        <img className="sufIcon" src={props.icon} alt="input-icon" />
      ) : null}
    </StyledContainer>
  );
};

export default Input;
