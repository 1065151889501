import { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import { useTranslation } from "react-i18next";
import { ProjectTitle } from "consts/global";
import { API } from "services/API/API";
import {
  IHomeBlog,
  IHomeCover,
  IHomeProducts,
  IHomeProject,
  IHomeSlider,
  IHomeVlog,
} from "types/home";
import { useCanonicalUrl } from "services/hooks/canonical";
import HomeCars from "components/parts/home/cars";
import HomeBigSection from "components/parts/home/bigSection";
import HomeVlogs from "components/parts/home/videos";
import HomeSlider from "components/parts/home/slider";
import Loader from "components/layout/Loader";
import Offers from "components/parts/home/offers";
import HomeProjects from "components/parts/home/projects";
import HomeNews from "components/parts/home/news";

import { HomePageContainer } from "./styled";
import { IListItem } from "components/parts/news";

const Homepage = () => {
  const { t, i18n } = useTranslation();
  const [loader, setLoader] = useState(true);
  const [cover, setCover] = useState<IHomeCover>();
  const [secondCover, setSecondCover] = useState<IHomeCover>();
  const [blogs, setBlogs] = useState<IHomeBlog[]>([]);
  const [vlogs, setVlogs] = useState<IHomeVlog[]>([]);
  const [productCategories, setProductCategories] = useState<IHomeProducts[]>(
    []
  );
  const [offers, setOffers] = useState<IHomeProject[]>([]);
  const [slider, setSliders] = useState<IHomeSlider[]>([]);
  const [products, setProducts] = useState<IListItem[]>([]);
  const url = useCanonicalUrl();
  const [meta, setMeta] = useState({
    title: `${t("main")} | ${ProjectTitle}`,
    description: t("main"),
    canonical: url,
    meta: {
      "og:title": `TCE | ${ProjectTitle}`,
      "og:description": t("main"),
      "og:url": url,
      "og:image": "https://back.tce.ge/storage/506/paralax--3cx-min-(1).jpg",
      "og:type": "website",
    }
  });

  const lang = i18n.language;

  useEffect(() => {
    API.home.getHomeData().then((res) => {
      setMeta({
        title: res.meta_tags.meta_title,
        description: res.meta_tags.meta_description,
        canonical: url,
        meta: {
          "og:title": `${res.meta_tags.meta_title} | ${ProjectTitle}`,
          "og:description": res.meta_tags.meta_description,
          "og:url": url,
          "og:image": res.meta_tags.og_image,
          "og:type": "website",
        }
      })
      setProducts(
        res?.products?.map(
          (i: {
            url: string;
            slug: string;
            img: string;
            description: string;
            price: string;
            media: { url: string };
          }) => {
            i.url = i.slug;
            i.img = i.media?.url;
            return i;
          }
        ) || []
      );
      setBlogs(res?.blogs);
      setVlogs(
        res?.vlogs?.map((item: any) => {
          return {
            ...item,
            description: "",
            url: item.slug,
            video:
              item.video_source === "youtube"
                ? item.youtube_url
                : item?.file?.url,
            img: item?.media?.url,
            youtubeUrl: item.video_source === "youtube" ? item.youtube_url : "",
            videoUrl:
              item.video_source === "youtube"
                ? item.youtube_url
                : item?.file?.url,
          };
        })
      );
      setSliders(res?.sliders);
      setProductCategories(res?.productCategories.slice(0, 8));
      setCover(res?.cover);
      setSecondCover(res?.second_cover);
      setOffers(
        res?.offers?.map((item: { [x: string]: { url: string }; img: any }) => {
          item.img = item["main-image"]?.url || "";
          return item;
        })
      );
      setLoader(false);
    });
  }, [lang]);

  localStorage.removeItem("page-category");
  localStorage.removeItem("page-title");

  return (
    <DocumentMeta {...meta}>
      {loader && <Loader />}
      <HomePageContainer>
        <section className="mb-1000">
          <HomeSlider slider={slider} />
        </section>

        {!!products?.length && (
          <section className="mb-1000">
            <HomeProjects
              projects={products}
              title={t("catalogue")}
              discoverMore
            />
          </section>
        )}

        {!!cover && !!cover?.media?.url && (
          <section className="mb-1000">
            <HomeBigSection cover={cover} />
          </section>
        )}

        <div className="container">
          {productCategories && (
            <section className="mb-1000">
              <HomeCars products={productCategories} scroll />
            </section>
          )}
        </div>

        {!!offers.length && (
          <section
            className="grey-bg pt-1000 pb-1000"
            style={{ overflow: "hidden" }}
          >
            <Offers offers={offers} />
          </section>
        )}

        <div className="container">
          {blogs && blogs.length > 0 && (
            <section className="mt-1000 mb-1000">
              <HomeNews blogs={blogs} />
            </section>
          )}
        </div>

        {!!secondCover && !!secondCover?.media?.url && (
          <section>
            <HomeBigSection cover={secondCover} />
          </section>
        )}

        {vlogs && vlogs.length > 0 && (
          <div className="pt-1000">
            <HomeVlogs vlogs={vlogs} />
          </div>
        )}
      </HomePageContainer>
    </DocumentMeta>
  );
};

export default Homepage;
