import axiosInstance from "..";

const getCategories = (footer?: boolean) => {
  return axiosInstance
    .get("products/getcategories", { params: { footerList: footer ? 1 : 0 } })
    .then((res) => {
      return res.data;
    });
};

const getProjects = (params?: any, models?: string, brands?: string) => {
  return axiosInstance
    .get(
      `products/${models ? `?${models}` : ""}${brands ? (models ? `&${brands}` : `?${brands}`) : ""}`,
      {
        params,
      }
    )
    .then((res) => {
      return res.data;
    });
};

const getProjectsFilters = (params?: any, models?: string, brands?: string) => {
  return axiosInstance
    .get(
      `products/getFilters/${models ? `?${models}` : ""}${brands ? (models ? `&${brands}` : `?${brands}`) : ""}`,
      {
        params,
      }
    )
    .then((res) => {
      return res.data;
    });
};

const getSingeProject = (slug: string) => {
  return axiosInstance.get(`products/show/${slug}`).then((res) => {
    return res.data;
  });
};

const getSimilarProducts = (id: string) => {
  return axiosInstance.get(`products/getsimilars/${id}`).then((res) => {
    return res.data;
  });
};

const sendMobile = (
  phone: number | string,
  model_id: number | boolean | null
) => {
  return axiosInstance
    .post("phonerequest/product_store", {
      phone: phone,
      model_id: model_id,
    })
    .then((res) => {
      return res.data;
    });
};

const phonerequestCount = (id: number) => {
  return axiosInstance
    .post(`phonerequest/phone_view?product_id=${id}`)
    .then((res) => {
      return res.data;
    });
};

export const projects = {
  getCategories,
  getProjects,
  getProjectsFilters,
  getSingeProject,
  sendMobile,
  phonerequestCount,
  getSimilarProducts,
};
