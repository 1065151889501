import { RefObject, useEffect, useState } from "react";
// import { ReactComponent as Baner } from "assets/images/menu-baner.svg";
import { ReactComponent as Logo } from "assets/images/tegeta_holding_logo.svg";
import { ReactComponent as LogoEN } from "assets/images/tegeta_holding_logo_en.svg";
// import { ReactComponent as LogoWhite } from "assets/images/tegeta_holding_logo_white.svg";
import { ReactComponent as LinkIcon } from "assets/icons/linkIcon.svg";
import { ReactComponent as CloseIcon } from "assets/icons/menuClose.svg";
import { ReactComponent as ArrowLeft } from "assets/icons/CaretLeft.svg";
import { GlobalMenuContainer } from "./styled";
import { API } from "services/API/API";
import { useTranslation } from "react-i18next";

interface IData {
  id: number;
  title: string;
  link: string;
  items: {
    id: number;
    title: string;
    link: string;
    logo: {
      url: string;
      responsive: string;
      thumb: string;
    };
  }[];
}

function Global({
  node,
  open,
  closeMenu,
}: {
  node: RefObject<HTMLDivElement>;
  open: boolean;
  closeMenu: () => void;
}) {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<IData[]>([]);
  const [banner, setBanner] = useState("");

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";

      API.globals.getExternalmenu().then((res) => {
        setData(res?.data);
        setBanner(res?.banner_url);
      });
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  return (
    <GlobalMenuContainer className={`${open ? "open" : ""}`} ref={node}>
      <div className="menu-container">
        <div className="menu-header">
          <ArrowLeft className="back-icon" onClick={closeMenu} />

          <h2>{t("menu")}</h2>

          <CloseIcon
            className="close-icon"
            onClick={(e) => {
              closeMenu();
            }}
          />
        </div>

        <div className="content">
          <div className="header">
            {i18n.language === "en" ? <LogoEN /> : <Logo />}
            <div className="line" />
          </div>

          <div>
            {/* <div className="baner-text">
              <LogoWhite />

              <p>
                {i18n.language === "en"
                  ? "Learn more about Tegeta Holding"
                  : "გაიგე მეტი თეგეტა ჰოლდინგის შესახებ"}
              </p>
            </div>

            <Baner /> */}
            <img className="baner" alt="banner" src={banner} />
          </div>

          {!!data?.length &&
            data.map((item) => (
              <div
                className="display-flex flex-column gap-100"
                key={item.id}
                style={{ marginBottom: 16 }}
              >
                <div>
                  <div className="title-wrapper">
                    <div>{item.title}</div>
                    <a href={item.link} target="_blank" rel="noreferrer">
                      <LinkIcon /> {item.link}
                    </a>
                  </div>

                  <div className="items-row">
                    {!!item.items?.length &&
                      item.items.map((i) => (
                        <a
                          className="cols item"
                          href={i.link}
                          key={i.id}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="item-img-container">
                            <img src={i.logo.url} alt={i.title} />
                          </div>

                          <div className="item-title">{i.title}</div>
                        </a>
                      ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </GlobalMenuContainer>
  );
}

export default Global;
