/* eslint-disable no-restricted-globals */
import { globalStyles } from "consts/spaces";
import React, { ButtonHTMLAttributes } from "react";
import { useNavigate } from "react-router-dom";
import { currentLangUrl } from "services/hooks/canonical";
import styled, { css } from "styled-components";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | "primary"
    | "default"
    | "warning"
    | "danger"
    | "success"
    | "positive"
    | "accept"
    | "light"
    | "base"
    | "base-white";
  size?: "xs" | "sm" | "lg" | "xl";
  bold?: boolean;
  fullWidth?: boolean;
  link?: string;
}

const CustomButton = styled.button<ButtonProps>`
  padding: ${globalStyles.spaces[100]} ${globalStyles.spaces[200]};
  font-size: ${globalStyles.fonts.p16};
  font-style: normal;
  line-height: 24px;
  color: ${(props) => props.theme.colorTextInverse};
  font-family: "FiraGO", sans-serif;
  border: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: ${globalStyles.radius.cardRadiusCorner100};
  line-height: 24px;
  font-weight: ${(props) => (props.bold ? 700 : 400)};
  outline: none;
  transition:
    border 0.3s,
    background-color 0.3s,
    color 0.3s;

  /* Variant styles */
  ${({ variant }) => {
    switch (variant) {
      case "primary":
        return css`
          color: #fff;
          background-color: ${(props) =>
            props.theme.colorBackgroundBrandPrimaryBold};

          &:hover,
          &:focus,
          &:visited,
          &:link,
          &:active,
          &.active {
            background-color: ${(props) =>
              props.theme.colorBackgroundBrandPrimaryBoldHovered};
          }
        `;
      case "default":
        return css`
          background-color: ${(props) =>
            props.theme.colorBackgroundNeutralBoldest};

          &:hover,
          &:focus,
          &:visited,
          &:link,
          &:active,
          &.active {
            background-color: ${(props) =>
              props.theme.colorBackgroundNeutralBoldestHovered};
          }
        `;
      case "danger":
        return css`
          background-color: ${(props) => props.theme.colorTextDanger};

          &:hover {
            background-color: #f03348;
          }

          &:link,
          &:active,
          &.active {
            background-color: #ac0214;
          }
        `;
      case "success":
        return css`
          background-color: ${(props) =>
            props.theme.colorBackgroundSuccessBold};

          &:hover,
          &:focus,
          &:visited,
          &:link,
          &:active,
          &.active {
            background-color: ${(props) =>
              props.theme.colorBackgroundSuccessBoldHovered};
          }
        `;
      case "positive":
        return css`
          background-color: ${(props) =>
            props.theme.ColorBackgroundAccentLimeBolderHovered};

          &:hover,
          &:focus,
          &:visited,
          &:link,
          &:active,
          &.active {
            background-color: ${(props) =>
              props.theme.colorBackgroundSuccessBold};
          }
        `;
      case "accept":
        return css`
          background-color: ${(props) =>
            props.theme.colorBackgroundBrandSecondaryBold};

          &:hover,
          &:focus,
          &:visited,
          &:link,
          &:active,
          &.active {
            background-color: ${(props) =>
              props.theme.colorBackgroundBrandSecondaryBoldHovered};
          }
        `;
      case "warning":
        return css`
          color: ${(props) => props.theme.colorTextWarningInverse};
          background-color: ${(props) =>
            props.theme.colorBackgroundWarningBold};

          &:hover,
          &:focus,
          &:visited,
          &:link,
          &:active,
          &.active {
            background-color: ${(props) =>
              props.theme.colorBackgroundWarningBoldHovered};
          }
        `;
      case "light":
        return css`
          color: ${(props) => props.theme.colorTextPrimary};
          background-color: rgba(9, 30, 66, 0.06);

          &:hover {
            background-color: #f7f8f9;
          }

          &.active {
            color: ${(props) => props.theme.colorTextPrimary};
            background: var(
              --Color-Background-Neutral-Default,
              rgba(9, 30, 66, 0.1)
            );
          }
        `;
      case "base":
        return css`
          color: ${(props) => props.theme.colorTextPrimary};
          background-color: ${(props) =>
            props.theme.colorBackgroundNeutralDefault};

          &.active,
          &:hover {
            background-color: ${(props) =>
              props.theme.colorBackgroundSurfaceSurfaceHovered};
          }
        `;
      case "base-white":
        return css`
          background-color: transparent;
          color: #fff;

          &.active,
          &:hover {
            background-color: ${(props) =>
              props.theme.colorBackgroundBrandPrimaryBold};
          }
        `;
      default:
        return css`
          background-color: ${(props) =>
            props.theme.colorBackgroundBrandPrimaryBold};
        `;
    }
  }}

  /* Size styles */
  ${({ size }) => {
    switch (size) {
      case "xs":
        return css`
          font-size: ${globalStyles.fonts.p14};
          padding: ${globalStyles.spaces[25]} ${globalStyles.spaces[100]};
        `;
      case "sm":
        return css`
          font-size: ${globalStyles.fonts.p14};
          padding: ${globalStyles.spaces[50]} ${globalStyles.spaces[150]};
        `;
      case "lg":
        return css`
          padding: ${globalStyles.spaces[150]} ${globalStyles.spaces[300]};
        `;
      case "xl":
        return css`
          line-height: 32px;
          letter-spacing: 0.64px;
          padding: ${globalStyles.spaces[150]} ${globalStyles.spaces[400]};
        `;
      default:
        return null;
    }
  }}

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    padding: ${globalStyles.spaces[100]} ${globalStyles.spaces[200]};
    font-size: ${globalStyles.fonts.p14};

    ${({ size }) => {
      switch (size) {
        case "xl":
          return css`
            font-size: ${globalStyles.fonts.p16};
            padding: ${globalStyles.spaces[150]} ${globalStyles.spaces[250]};
          `;
        default:
          return null;
      }
    }}
  }

  /* Disabled styles */
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      display: block;
      width: 100%;
    `}
`;

export const Button: React.FC<ButtonProps> = ({ link, children, ...props }) => {
  const navigate = useNavigate();
  const redirect = (l: string) => {
    if (l.includes(window.location.href)) {
      const url = l.replace(window.location.href, "");
      navigate(currentLangUrl() + url);
    } else {
      location.href = l;
    }
  };
  return link ? (
    <CustomButton onClick={() => redirect(link)} {...props}>
      {children}
    </CustomButton>
  ) : (
    <CustomButton {...props}>{children}</CustomButton>
  );
};
