import { useTranslation } from "react-i18next";
import { ReactComponent as FilterIcon } from "assets/icons/FilterIcon.svg";
import { Button } from "components/styled/Button";
import { FilterCotainerSmall } from "./styled";

type Props = {
  total: number;
  openFilter: () => void;
};

function FilterMobile({ total, openFilter }: Props) {
  const { t } = useTranslation();

  return (
    <FilterCotainerSmall>
      <Button className="filter-button" onClick={() => openFilter()}>
        <FilterIcon />

        {t("filter")}
      </Button>

      <Button variant="light" style={{ background: "transparent" }} disabled>
        {t("found")} {total} {t("product")}
      </Button>
    </FilterCotainerSmall>
  );
}

export default FilterMobile;
