import ListItem, { IListItem } from "../news";
import DiscoverMore from "components/styled/DiscoverMore";
import { FC, useEffect, useState } from "react";
import { SimilarProjects } from "pages/Projects/styled";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowLeft } from "assets/icons/CaretLeft.svg";
import { useLocation, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import { useIsTouchDevice } from "helpers";
import { Slider } from "components/styled/Slider";

interface IProps {
  projects: IListItem[];
  title?: string;
  discoverMore?: boolean;
  brand?: boolean;
}

export const deviceBreakpoints = {
  mobile: 480,
  tablet: 744,
  md: 1280,
  lg: 1680,
};

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade]);

const HomeProjects: FC<IProps> = ({ projects, title, discoverMore, brand }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { id } = useParams();
  const [screenSizes, setScreenSizes] = useState<number>(1);
  const touchDevice = useIsTouchDevice();

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (projects.length < 4) {
        setScreenSizes(projects.length);
      } else {
        if (
          screenWidth >= deviceBreakpoints.tablet &&
          screenWidth < deviceBreakpoints.md
        ) {
          setScreenSizes(2.5);
        } else if (
          screenWidth >= deviceBreakpoints.md &&
          screenWidth < deviceBreakpoints.lg
        ) {
          setScreenSizes(3.5);
        } else if (screenWidth >= deviceBreakpoints.lg) {
          setScreenSizes(4);
        } else {
          setScreenSizes(1.2);
        }
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [projects.length]);

  const limit = brand ? 4 : 5;

  return (
    <>
      <SimilarProjects>
        <div className="container">
          <h2 className="mt-0 mb-300">
            {title ? title : t("findDesiredModel")}
          </h2>
        </div>

        {projects?.length < limit ? (
          <div className="container">
            <div className="row mobile-slide-list">
              {projects.map((item, index) => (
                <div
                  key={item.title + index}
                  className={`col-3 col-md-4 col-xs-12`}
                >
                  <ListItem
                    showDesc
                    withoutspace
                    superSmallImg
                    projectsList
                    {...item}
                    url={`/product/${item.url}`}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Slider>
            <div className="slider-services">
              <div
                className={`container arrows ${touchDevice ? "" : "with-hover"}`}
              >
                <button className="arrow-left arrow">
                  <ArrowLeft />
                </button>
                <button className="arrow-right arrow">
                  <ArrowLeft />
                </button>
              </div>

              <Swiper
                navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
                slidesPerView={screenSizes}
                freeMode={true}
                pagination={{ clickable: true, dynamicBullets: true }}
                initialSlide={0}
                className="projects-slider"
              >
                {projects.map((item, index) => (
                  <SwiperSlide className="slider-item" key={`room-${index}`}>
                    <ListItem
                      showDesc
                      superSmallImg
                      projectsList
                      {...item}
                      url={`/product/${item.url}`}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Slider>
        )}
      </SimilarProjects>

      {discoverMore && (
        <div className="display-flex justify-center mt-600">
          <DiscoverMore
            url={
              location.pathname.includes("brand")
                ? `/brand/${id}/products`
                : `/products`
            }
          />
        </div>
      )}
    </>
  );
};

export default HomeProjects;
