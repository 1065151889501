import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "components/styled/Input";
import Modal from ".";
import { useTranslation } from "react-i18next";
import CustomCheckbox from "components/styled/Checkbox";
import Dropdown from "components/styled/Dropdown";
import { API } from "services/API/API";

interface IProps {
  open: boolean;
  part?: boolean;
  close: () => void;
}

export interface IStoreService {
  full_name: string;
  company: string;
  phone: string;
  service_id: number | null;
  agree_personal_info: boolean;
  part?: boolean;
}

const initialValues = {
  full_name: "",
  company: "",
  phone: "",
  service_id: null,
  agree_personal_info: false,
};

function RequestServiceModal({ open, part, close }: IProps) {
  const { i18n, t } = useTranslation();
  const [formData, setFormData] = useState<IStoreService>(initialValues);
  const [services, setServices] = useState([]);

  const changeText = (key: string, value: string) => {
    const formDataCopy = { ...formData };
    // @ts-ignore
    formDataCopy[key] = value;
    setFormData(formDataCopy);
  };

  useEffect(() => {
    if (open) {
      API.services.getservices().then((res) => {
        setServices(
          res.data.map((i: { id: number; title: string }) => ({
            value: i.id,
            label: i.title,
          }))
        );
      });
    }
  }, [open, i18n.language]);

  const handleSubmit = () => {
    if (!formData.phone) {
      toast(t("errors.phone"), { type: "error" });
      return false;
    }

    if (formData.phone && formData.phone.length !== 9) {
      toast(t("errors.phonelength"), { type: "error" });
      return false;
    }

    API.services
      .storeServices({ ...formData, part: part })
      .then((res) => {
        if (res && res?.status) {
          handleClose();
          toast(t("sentSuccessfully"), { type: "success" });
        } else {
          toast.error(t("errorOccurred"));
        }
      })
      .catch(() => {
        // if (err?.response?.status === 429) {
        //   toast.error(t("tooManyRequests"));
        // } else {
        //   toast.error(t("errorOccurred"));
        // }
      });
  };

  const handleClose = () => {
    setFormData(initialValues);
    close();
  };

  return (
    <>
      <ToastContainer />
      <Modal
        title={part ? "partRequest" : "requestService"}
        open={open}
        close={handleClose}
        onSubmit={handleSubmit}
      >
        <div
          className="display-flex flex-column gap-200"
          style={{ width: "100%" }}
        >
          <Input
            onChange={(e) => changeText("full_name", e.target.value)}
            value={formData.full_name}
            transparent
            placeholder={t("nameAndSurname")}
          />

          <Input
            onChange={(e) => changeText("company", e.target.value)}
            value={formData.company}
            transparent
            placeholder={t("yourCompany")}
          />

          {!part && (
            <Dropdown
              items={services}
              label={t("chooseService")}
              onSelect={(val) => changeText("service_id", val)}
              value={formData.service_id}
            />
          )}

          <Input
            onChange={(e) => changeText("phone", e.target.value)}
            value={formData.phone}
            transparent
            placeholder={t("phone")}
            type="number"
            mobile
          />

          <CustomCheckbox
            checked={formData.agree_personal_info}
            onChange={(checked) =>
              setFormData((state) => ({
                ...state,
                agree_personal_info: checked,
              }))
            }
            label={t("agreePersonalData")}
          />
        </div>
      </Modal>
    </>
  );
}

export default RequestServiceModal;
