import { about } from "./endpoints/about";
import { accessories } from "./endpoints/accessories";
import { blogs } from "./endpoints/blog";
import { vlogs } from "./endpoints/vlogs";
import { cars } from "./endpoints/cars";
import { contact } from "./endpoints/contact";
import { home } from "./endpoints/home";
import { mopeds } from "./endpoints/moped";
import { news } from "./endpoints/news";
import { offers } from "./endpoints/offers";
import { projects } from "./endpoints/projects";
import { leasing } from "./endpoints/leasing";
import { globals } from "./endpoints/global";
import { services } from "./endpoints/services";
import { brand } from "./endpoints/brand";

export const API = {
  news,
  home,
  offers,
  cars,
  mopeds,
  accessories,
  blogs,
  vlogs,
  about,
  projects,
  contact,
  leasing,
  globals,
  services,
  brand
};
