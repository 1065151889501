import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const FilterCotainer = styled.div``;

export const FilterList = styled.div`
  transition: all 0.3s ease-in-out;

  ::-webkit-scrollbar {
    width: 8px !important;
    height: auto !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #091e424f !important;
    border-radius: 16px !important;
  }

  ::-webkit-scrollbar-button {
    width: 0px !important;
    height: 0px !important;
  }

  .category,
  .sub-category {
    cursor: pointer;
    color: ${(props) => props.theme.colorLinkDefault};

    &.active {
      font-weight: bold;
    }
  }

  .category {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .arrow-up {
    font-size: 14px;
    font-weight: 500;
    transform: rotate(180deg);
    margin-top: 4px;
  }

  .showAllModels {
    color: #000825;
    cursor: pointer;
  }

  .filters-container {
    max-height: 284px;
    overflow-y: auto;

    // scrollbar-color: #091e424f white;
    // scrollbar-width: thin;

    // &::-webkit-scrollbar {
    //   width: 8px !important;
    // }

    // /* Handle */
    // &::-webkit-scrollbar-thumb {
    //   background: #091e424f !important;
    //   border-radius: 16px !important;
    // }
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    padding-bottom: ${globalStyles.spaces[100]};

    .blog-cats {
      scrollbar-width: none;
      width: 100%;

      &::-webkit-scrollbar {
        display: none;
      }

      @media (max-width: ${globalStyles.breackPoints.md}) {
        overflow: scroll;

        & > div {
          display: block;
          min-width: 120%;
          min-width: auto;
          width: max-content;

          button {
            margin-right: ${globalStyles.spaces[200]};

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  button {
    white-space: nowrap;
    font-weight: 700;
    font-size: ${globalStyles.fonts.b20};
    color: ${(props) => props.theme.colorTextPrimary};
  }
`;

export const FilterCotainerSmall = styled.div`
  display: none;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.colorBackgroundInputHovered};
  margin-bottom: 40px;

  .filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    min-width: 250px;
  }

  @media (max-width: 1024px) {
    display: flex;
  }

  @media (max-width: ${globalStyles.breackPoints.tablet}) {
    margin-bottom: 24px;
  }

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 24px;

    .filter-button {
      min-width: 100%;
    }
  }
`;
