import { IStoreService } from "./../../../components/parts/modal/RequestServiceModal";
import axiosInstance from "..";

const getservicePage = () =>
  axiosInstance.get("getservicepage").then((res) => res.data);

const getservices = () => axiosInstance.get("services").then((res) => res.data);

const storeServices = (data: IStoreService) =>
  axiosInstance.post("services/store", data).then((res) => res.data);

export const services = {
  getservicePage,
  getservices,
  storeServices,
};
