/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { currentLangUrl } from "services/hooks/canonical";
import styled from "styled-components";

const BreadcrumbsContainer = styled.div`
  span,
  a {
    color: #172b4d;
    font-size: 14px;
    line-height: 22px;

    &:hover {
      color: #172b4d;
    }
  }
`;

const Breadcrumbs: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const text = localStorage.getItem("page-category");
  const desc = localStorage.getItem("page-title");

  useEffect(() => {
    setTimeout(() => {
      const newTitle = localStorage.getItem("page-category");
      const newDesc = localStorage.getItem("page-title");
      if (newTitle) {
        setTitle(newTitle);
      } else {
        setTitle("");
      }

      if (newDesc) {
        setDescription(newDesc);
      } else {
        setDescription("");
      }
    }, 1000);
  }, [text, desc, localStorage, pathname]);

  return (
    <BreadcrumbsContainer className="flex">
      <Link to={currentLangUrl()}>{t("main")}</Link>
      {title && (
        <>
          <span className="pl-100 pr-100">/</span>
          <span>{title}</span>
        </>
      )}
      {description && (
        <>
          <span className="pl-100 pr-100">/</span>
          <span>{description}</span>
        </>
      )}
    </BreadcrumbsContainer>
  );
};

export default Breadcrumbs;
