import { Button } from "components/styled/Button";
import { globalStyles } from "consts/spaces";
import { t } from "i18next";
import { FC } from "react";
import styled from "styled-components";

interface IProps {
  iframeUrl: string;
  latitude: string;
  longitude: string;
}

const MapContainer = styled.main`
  position: relative;
  height: 400px;
  white-space: nowrap;

  & > button {
    position: absolute;
    bottom: 58px;
    right: 58px;
  }

  @media (max-width: ${globalStyles.breackPoints.lgTablet}) {
    margin-left: -16px;
    width: 100dvw;

    button {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }
  }
`;

const Map: FC<IProps> = ({ iframeUrl, latitude, longitude }) => {
  function generateGoogleMapsUrl(latitude: string, longitude: string): string {
    const baseUrl = "https://www.google.com/maps/dir/?api=1";
    const origin = "Current+Location";
    const destination = `${latitude},${longitude}`;
    return `${baseUrl}&origin=${origin}&destination=${destination}`;
  }

  return (
    <MapContainer>
      <iframe
        src={iframeUrl}
        width="100%"
        title="map"
        height="400"
        style={{ border: 0, filter: "invert(10%)" }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
      <Button
        link={generateGoogleMapsUrl(latitude, longitude)}
        size="lg"
        variant="default"
      >
        {t("getDirections")}
      </Button>
    </MapContainer>
  );
};

export default Map;
