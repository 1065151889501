import { CSSProperties, FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ListItemContainer } from "./styled";
import Play from "assets/icons/PlayCircle.svg";
import { currentLangUrl } from "services/hooks/canonical";

function removeHtmlTags(input: string): string {
  // Define a regular expression to match HTML tags
  const htmlRegex = /<[^>]*>/g;
  // Replace all instances of HTML tags with an empty string
  return input.replace(htmlRegex, "");
}

export interface IListItem {
  title: string;
  description: string;
  url: string;
  img: string;
  inside?: boolean;
  smallImg?: boolean;
  withoutspace?: boolean;
  video?: boolean;
  superSmallImg?: boolean;
  large?: boolean;
  videoUrl?: string;
  isMore?: boolean;
  maxDescription?: boolean;
  onOpen?: () => void;
  blogMax?: boolean;
  showDesc?: boolean;
  projectsList?: boolean;
  youtubeUrl?: string;
  price?: string;
  showPrice?: boolean;
  style?: CSSProperties;
}

const ListItem: FC<Partial<IListItem>> = ({
  title,
  url,
  description,
  img,
  inside,
  smallImg,
  withoutspace,
  superSmallImg,
  video,
  onOpen,
  isMore,
  maxDescription,
  large,
  blogMax,
  projectsList,
  showDesc,
  youtubeUrl,
  price,
  showPrice,
  style,
}) => {
  const { t } = useTranslation();
  const imgRef = useRef<HTMLImageElement>(null);
  const [imgWidth, setImgWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        const updateWidth = () => {
          if (imgRef.current) {
            setImgWidth(imgRef?.current?.offsetWidth);
          }
        };

        updateWidth();

        window.addEventListener("resize", updateWidth);

        return () => {
          window.removeEventListener("resize", updateWidth);
        };
      }, 0);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [imgRef?.current?.offsetWidth]);

  return (
    <ListItemContainer
      className={inside ? "inside" : ""}
      withoutspace={!!withoutspace}
      style={style}
    >
      <Link
        onClick={(e) => {
          if (onOpen) {
            onOpen();
            e.preventDefault();
          }
        }}
        to={currentLangUrl() + url}
      >
        <img
          ref={imgRef}
          className={`${
            smallImg
              ? "smallImg"
              : superSmallImg
                ? "superSmallImg"
                : large
                  ? "large"
                  : projectsList
                    ? "projectsList"
                    : ""
          } ${video ? "video" : ""}`}
          src={img}
          alt={video ? "vlog" : "blog"}
          style={
            projectsList
              ? { height: imgWidth / 1.8 }
              : smallImg
                ? { height: imgWidth / 1.33 }
                : video
                  ? { height: imgWidth / 1.96 }
                  : superSmallImg
                    ? {}
                    : { height: imgWidth / 1.62 }
          }
        />

        {video && (
          <div
            className={`video ${superSmallImg ? "superSmallImg" : ""}`}
            style={{ height: imgWidth / 1.96 }}
          >
            <img src={Play} alt={title} />
          </div>
        )}

        {inside ? (
          <div className="absolute">
            <p>{description}</p>
            <h3 className="font-weight-700">{title}</h3>
          </div>
        ) : (
          <>
            {!!price && showPrice && (
              <h3 className="font-weight-400">{price}</h3>
            )}
            <h3
              className={`font-weight-700 ${blogMax ? "blogMax" : ""} ${
                superSmallImg ? "superSmallImg" : ""
              } ${video ? "vlog" : ""} ${projectsList ? "projectsList" : ""}`}
            >
              {title}
            </h3>
            {description && (
              <p
                className={`${
                  maxDescription
                    ? `maxDescription ${blogMax ? "blogMax" : ""}`
                    : ""
                } ${video ? "vlog" : ""}`}
              >
                {showDesc ? (
                  <span className="one-line">
                    {removeHtmlTags(description)}
                  </span>
                ) : (
                  description
                )}
              </p>
            )}
            {isMore && (
              <Link className="main-color" to={currentLangUrl() + url}>
                {t("more")}
              </Link>
            )}
          </>
        )}
      </Link>

      {!!youtubeUrl && (
        <a
          className="main-color center mt-100"
          href={youtubeUrl}
          target="_blank"
          rel="noreferrer"
        >
          {t("watchOnYoutube")}
        </a>
      )}
    </ListItemContainer>
  );
};

export default ListItem;
