import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import CustomCheckbox from "components/styled/Checkbox";
import Input from "components/styled/Input";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { API } from "services/API/API";
import Modal from ".";
import { currentLangUrl } from "services/hooks/canonical";

interface IProps {
  open: boolean;
  productId?: number;
  close: () => void;
}

const initialValues = {
  full_name: "",
  phone: "",
  agree_news: false,
};

function LeasingModal({ open, productId, close }: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialValues);

  const handleClose = () => {
    close();
    setFormData(initialValues);
  };

  const changeText = (key: string, value: string) => {
    const formDataCopy = { ...formData };
    // @ts-ignore
    formDataCopy[key] = value;
    setFormData(formDataCopy);
  };

  const handleSubmit = () => {
    if (!formData.phone) {
      toast(t("errors.phone"), { type: "error" });
      return false;
    }

    if (formData.phone && formData.phone.length !== 9) {
      toast(t("errors.phonelength"), { type: "error" });
      return false;
    }

    if (formData.phone && formData.phone.length === 9) {
      API.leasing
        .storeLeasing(
          productId ? { ...formData, product_id: productId } : formData
        )
        .then((res) => {
          if (res.status) {
            navigate(currentLangUrl() + "/success");
          } else {
            toast.error(t("errorOccurred"));
          }
        })
        .catch((err) => {
          if (err.response?.status === 429) {
            toast.error(t("tooManyRequests"));
          } else {
            toast.error(t("errorOccurred"));
          }
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal
        title={t("requestLeasing")}
        open={open}
        close={handleClose}
        onSubmit={handleSubmit}
      >
        <div className="leasing-container">
          <h2>{t("waitForCall")}</h2>
          <p>{t("waitForCallText")}</p>

          <div className="form-container">
            <div className="form">
              <div
                className="display-flex flex-column gap-200"
                style={{ width: "100%" }}
              >
                <Input
                  onChange={(e) => changeText("full_name", e.target.value)}
                  value={formData.full_name}
                  transparent
                  placeholder={t("nameAndSurname")}
                />

                <Input
                  onChange={(e) => changeText("phone", e.target.value)}
                  value={formData.phone}
                  transparent
                  placeholder={t("phone")}
                  type="number"
                  mobile
                />

                <CustomCheckbox
                  checked={formData.agree_news}
                  onChange={(checked) =>
                    setFormData((state) => ({ ...state, agree_news: checked }))
                  }
                  label={t("agreePersonalData")}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default LeasingModal;
