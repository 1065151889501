/* eslint-disable react-hooks/exhaustive-deps */
import { Title } from "components/styled/Title";
import { ContactContainer } from "./styled";
import Map from "components/parts/map";
import ContactForm from "components/parts/forms/contactForm";
import { useEffect, useState } from "react";
import { API } from "services/API/API";
import { useCanonicalUrl } from "services/hooks/canonical";
import DocumentMeta from "react-document-meta";
import { useTranslation } from "react-i18next";
import { ProjectTitle } from "consts/global";

const Contact = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState({
    address: "",
    email_1: "",
    email_2: "",
    phone_1: "",
    title: "",
    latitude: "",
    longitude: "",
    products_dropdown: [],
    short_description: "",
  });
  const [countryCodes] = useState([]);

  useEffect(() => {
    API.contact.getContactData().then((res) => {
      res.products_dropdown = Object.keys(res.products_dropdown).map(
        (key: any) => {
          return {
            label: res.products_dropdown[key],
            value: key,
          };
        }
      );
      setData(res);
      // setCountryCodes(res.country_codes);
    });
  }, [i18n.language]);

  const url = useCanonicalUrl();
  const meta = {
    title: `${t("contact")} | ${ProjectTitle}`,
    description: t("contact"),
    canonical: url,
  };

  localStorage.setItem("page-category", t("contact"));
  localStorage.removeItem("page-title");

  return (
    <DocumentMeta {...meta}>
      <ContactContainer className="container mt-1000">
        <Title>{data.title}</Title>
        <p dangerouslySetInnerHTML={{ __html: data.short_description }} />
        <div className="display-flex justify-space-between text-left gap-600">
          <div>
            <span>{t("address")}</span>
            <h5>{data.address}</h5>
          </div>
          <div>
            <span>{t("phone")}</span>
            <h5>
              <a href={`tel:${data.phone_1}`}>{data.phone_1}</a>
            </h5>
          </div>
          <div>
            <span>{t("email")}</span>
            <h5>
              <a href={`mailto:${data.email_1}`}>{data.email_1}</a>
              {data.email_2 && (
                <>
                  , <a href={`mailto:${data.email_2}`}>{data.email_2}</a>
                </>
              )}
            </h5>
          </div>
        </div>

        {data.latitude && data.latitude.length > 0 && (
          <Map
            latitude={data.latitude}
            longitude={data.longitude}
            iframeUrl={`https://maps.google.com/maps?q=${data.latitude},${data.longitude}&hl=es&z=16&amp&output=embed&&hl=${i18n.language}`}
          />
        )}

        <section className="mt-600">
          <ContactForm
            products={data.products_dropdown}
            countryCodes={countryCodes}
          />
        </section>
      </ContactContainer>
    </DocumentMeta>
  );
};

export default Contact;
