import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { API } from "services/API/API";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as LogoEn } from "assets/icons/logo_en.svg";

import Fb from "assets/icons/fb.svg";
import linkedin from "assets/icons/linkedin.svg";
import twitter from "assets/icons/twitter.svg";
import youtube from "assets/icons/youtube.svg";
import instagram from "assets/icons/instagram.svg";
import {
  FooterBottomMenu,
  FooterContainer,
  FooterCopyRight,
  FooterDescription,
  FooterMenu,
  FooterMenuLink,
} from "components/styled/Footer";
import { currentLangUrl } from "services/hooks/canonical";
import { ICategories } from "types/project";
import Breadcrumbs from "components/styled/Breadcrumbs";

const Footer = () => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language;
  const location = useLocation();
  const [data, setData] = useState({
    address: "",
    phone_1: "",
    twitter_link: "",
    youtube_link: "",
    facebook_link: "",
    linkedin_link: "",
    instagram_link: "",
    email_1: "",
  });
  const [text, setText] = useState(``);
  const [readMore, setReadMore] = useState(false);
  const [staticPages, setStaticPages] = useState<
    { slug: string; id: number; title: string }[]
  >([]);
  const [categories, setCategories] = useState<ICategories[]>([]);

  useEffect(() => {
    API.contact.getContactData().then((res) => {
      setText(res?.footer_text || "");
      setData(res);
    });

    API.globals.getStaticpage().then((res) => {
      setStaticPages(res.data);
    });

    API.projects.getCategories(true).then((res) => {
      setCategories(res?.data);
    });
  }, [lang]);

  const brand =
    (location.pathname.indexOf("/brand") > -1 &&
      location.pathname.split("/").length === 3) ||
    (location.pathname.indexOf("/en/brand") > -1 &&
      location.pathname.split("/").length === 4);

  return (
    <FooterContainer className="pt-500" style={brand ? { marginTop: 0 } : {}}>
      <div className="container">
        <Breadcrumbs />

        {!!text && (
          <FooterDescription className="mt-500 mb-500 f-p14">
            {readMore ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: text.slice(0, 300),
                }}
              />
            )}
            {text.length > 300 && (
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setReadMore(!readMore);
                }}
              >
                {" "}
                {!readMore ? t("...seeMore") : t("close")}
              </a>
            )}
          </FooterDescription>
        )}

        <FooterMenu className="display-flex pt-500 pb-500 row">
          <FooterMenuLink className="col-4 col-xs-12 col-sm-4 col-md-4 mr-auto large-footer-menu">
            <Link to={currentLangUrl()}>
              {lang === "en" ? (
                <LogoEn height={48} width={94} />
              ) : (
                <Logo height={48} width={94} />
              )}
            </Link>
            {/* <p
              dangerouslySetInnerHTML={{
                __html: t("footerDescription"),
              }}
            /> */}
            <div className="display-flex gap-300 mt-100 socials">
              {data?.twitter_link && (
                <a target="_blank" href={data?.twitter_link} rel="noreferrer">
                  <img src={twitter} alt="" />
                </a>
              )}
              {data?.linkedin_link && (
                <a target="_blank" href={data?.linkedin_link} rel="noreferrer">
                  <img src={linkedin} alt="" />
                </a>
              )}
              {data?.youtube_link && (
                <a target="_blank" href={data?.youtube_link} rel="noreferrer">
                  <img src={youtube} alt="" />
                </a>
              )}
              {data?.facebook_link && (
                <a target="_blank" href={data?.facebook_link} rel="noreferrer">
                  <img src={Fb} alt="" />
                </a>
              )}

              {data?.instagram_link && (
                <a target="_blank" href={data.instagram_link} rel="noreferrer">
                  <img src={instagram} alt="" />
                </a>
              )}
            </div>
          </FooterMenuLink>

          <div className="col-8 col-md-8 footer-bottom">
            <div className="row">
              {!!categories?.length && (
                <FooterMenuLink className="col-4 col-xs-6 col-sm-6 col-md-4">
                  <h4>{t("products")}</h4>

                  {categories.map((item) => (
                    <Link
                      key={item.id}
                      onClick={() => window.scrollTo(0, 0)}
                      to={`${currentLangUrl()}/products/${item.slug}`}
                    >
                      {item.title}
                    </Link>
                  ))}
                </FooterMenuLink>
              )}

              <FooterMenuLink className="col-4 col-xs-6 col-sm-6 col-md-4">
                <h4>{t("aboutUs")}</h4>
                <Link to={currentLangUrl() + "/about"}>
                  {t("aboutCompany")}
                </Link>
                <Link to={currentLangUrl() + "/offers"}>{t("offers")}</Link>
                <Link to={currentLangUrl() + "/vlog"}>{t("vlog")}</Link>
                <Link to={currentLangUrl() + "/blog"}>{t("blog")}</Link>
              </FooterMenuLink>

              <FooterMenuLink className="col-4 col-xs-6 col-sm-6 col-md-4">
                <h4>{t("contact")}</h4>
                <a href={`tel:${data?.phone_1}`}>{data?.phone_1}</a>
                <a href={`mailto:${data?.email_1}`}>{data?.email_1}</a>
                <span>{data?.address}</span>
              </FooterMenuLink>
            </div>
          </div>
        </FooterMenu>

        <FooterBottomMenu className="pt-500 pb-500 display-flex justify-center">
          {!!staticPages?.length &&
            staticPages.map((i) => (
              <Link to={currentLangUrl() + `/static/${i.slug}`} key={i.id}>
                {i.title}
              </Link>
            ))}
        </FooterBottomMenu>

        <FooterCopyRight className="pb-300 pt-300 text-center">
          {t("established")} 1869 · Copyright 2023
        </FooterCopyRight>
      </div>
    </FooterContainer>
  );
};

export default Footer;
