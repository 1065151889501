// CustomCheckbox.tsx
import React from "react";
import styled from "styled-components";

interface CustomCheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  onChange,
  label,
}) => {
  const handleCheckboxChange = () => {
    onChange(!checked);
  };

  return (
    <Label>
      <HiddenCheckbox
        type="checkbox"
        checked={checked}
        onChange={handleCheckboxChange}
      />

      <StyledCheckbox>{checked && <CheckIcon>✔</CheckIcon>}</StyledCheckbox>
      {label}
    </Label>
  );
};

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  color: #172b4d;
`;

const HiddenCheckbox = styled.input`
  display: none;
`;

const StyledCheckbox = styled.span`
  min-width: 14px;
  height: 14px;
  margin: 0 5px;
  border-radius: 4px;
  border: 2px solid #758195;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const CheckIcon = styled.span`
  color: #44546f;
  font-size: 8px;
`;

export default CustomCheckbox;
