import { globalStyles } from 'consts/spaces'
import styled from 'styled-components'

export const Badge = styled.a`
  border-radius: ${globalStyles.radius.cardRadiusCorner75};
  background: ${(props) => props.theme.colorBackgroundNeutralBolderPressed};
  font-size: ${globalStyles.fonts.p14};
  color: ${(props) => props.theme.colorTextInverse};
  padding: ${globalStyles.spaces[25]} ${globalStyles.spaces[100]};

  &:hover {
    background: ${(props) => props.theme.colorBackgroundNeutralBolderHovered};
    color: ${(props) => props.theme.colorTextInverse};
  }
`
