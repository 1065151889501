import { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProjectTitle } from "consts/global";
import { API } from "services/API/API";
import { AboutContainer } from "./styled";
import { SingleBlogContainer } from "pages/Blog/styled";

const About = () => {
  const { i18n } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState({
    title: "",
    cover_image: {
      url: "",
    },
    meta_description: "",
    long_description: "",
  });

  useEffect(() => {
    API.about.getAbout(id).then((res) => {
      setData(res);
      localStorage.setItem("page-category", res?.title);
      localStorage.removeItem("page-title");
    });
  }, [i18n.language, id]);

  return (
    <DocumentMeta
      title={`${data?.title} | ${ProjectTitle}`}
      description={data?.meta_description}
    >
      <AboutContainer className="container">
        <SingleBlogContainer>
          <h1 className="text-center">{data?.title}</h1>
          {data?.cover_image?.url && (
            <div className="img mt-600 mb-200">
              <img src={data?.cover_image?.url} alt={data?.title} />
            </div>
          )}
          <div className="about-content">
            <div dangerouslySetInnerHTML={{ __html: data.long_description }} />
          </div>
        </SingleBlogContainer>
      </AboutContainer>
    </DocumentMeta>
  );
};

export default About;
