import axiosInstance from "..";

const getCategories = () => {
  return axiosInstance.get("vlogs/getcategories").then((res) => {
    return res.data;
  });
};

const getVlogs = (page: number, byCategorySlug?: string) => {
  const params: { page: number; byCategorySlug?: string; per_page: number } = {
    page,
    per_page: 12,
  };

  if (byCategorySlug) {
    params["byCategorySlug"] = byCategorySlug;
  }

  return axiosInstance.get("vlogs", { params }).then((res) => {
    return res.data;
  });
};

export const vlogs = {
  getCategories,
  getVlogs,
};
