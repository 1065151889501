import axiosInstance from "..";

const getLanding = (slug: string, byCategorySlug: string) =>
  axiosInstance
    .get(`brandlanding/${slug}?byCategorySlug=${byCategorySlug}`)
    .then((res) => res.data?.data);

const getBrandLogo = (slug: string) =>
  axiosInstance.get(`getbrandlogo/${slug}`).then((res) => res.data);

export const brand = {
  getLanding,
  getBrandLogo,
};
