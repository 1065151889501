import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { currentLangUrl } from "services/hooks/canonical";
import { IItem } from "../list";

const Model = styled.div<{ url: string }>`
  object-fit: cover;
  border-radius: 4px;
  background:
    linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.5) 100%),
    url("${(props) => props.url}") lightgray 50% / cover no-repeat;
`;

function CarItem({ item }: { item: IItem }) {
  const imgRef = useRef<HTMLImageElement>(null);
  const [imgWidth, setImgWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        const updateWidth = () => {
          if (imgRef.current) {
            setImgWidth(imgRef?.current?.offsetWidth);
          }
        };

        updateWidth();

        window.addEventListener("resize", updateWidth);

        return () => {
          window.removeEventListener("resize", updateWidth);
        };
      }, 0);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [imgRef?.current?.offsetWidth]);

  return (
    <div
      className="single-model"
      key={item.title}
      style={imgWidth ? { height: imgWidth / 1.015 } : {}}
    >
      <Link to={currentLangUrl() + "/products/" + item.url}>
        <Model
          ref={imgRef}
          url={item.img}
          style={imgWidth ? { height: imgWidth / 1.015 } : {}}
          className="img"
        />

        <div className="single-model-info">
          <span>{item.model}</span>
          <h3>{item.title}</h3>
        </div>
      </Link>
    </div>
  );
}

export default CarItem;
