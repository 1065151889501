/* eslint-disable react-hooks/exhaustive-deps */
import { FC, RefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as MenuIcon } from "assets/icons/top-menu.svg";
import { HeaderTopContainer } from "./styled";
import { Link } from "react-router-dom";
import { navs } from "./consts";
import RequestServiceModal from "../modal/RequestServiceModal";
import Languages from "./languages";
import { currentLangUrl } from "services/hooks/canonical";

interface IProps {
  dark: boolean;
  node: RefObject<HTMLDivElement>;
  setLang?: (lang: string) => void;
  openGlobalMenu: () => void;
}

const HeaderTop: FC<IProps> = ({ node, setLang, openGlobalMenu }) => {
  const { t } = useTranslation();
  const [openReservation, setOpenReservation] = useState(false);

  return (
    <HeaderTopContainer>
      <div className="container display-flex align-items-center justify-space-between">
        <div className="display-flex align-items-center gap-300">
          <div
            className="side-menu"
            onClick={() => openGlobalMenu()}
            ref={node}
          >
            <MenuIcon />
          </div>

          {navs.map((i) => {
            if (i.link === "reserveService") {
              return (
                <div
                  key={i.title}
                  className="menu-item"
                  onClick={() => setOpenReservation(true)}
                >
                  {t(i.title)}
                </div>
              );
            } else {
              return (
                <Link
                  key={i.title}
                  to={currentLangUrl() + i.link}
                  className={`menu-item ${i.title === "leasing" ? "leasing" : ""}`}
                >
                  {t(i.title)}
                </Link>
              );
            }
          })}
        </div>

        <Languages setLang={setLang} />
      </div>

      <RequestServiceModal
        open={openReservation}
        close={() => setOpenReservation(false)}
      />
    </HeaderTopContainer>
  );
};

export default HeaderTop;
