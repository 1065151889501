import { globalStyles } from "consts/spaces";
import styled from "styled-components";

interface ListItemContainerProps {
  withoutspace?: boolean;
}

export const ListItemContainer = styled.div<ListItemContainerProps>`
  padding: ${(props) =>
    props.withoutspace
      ? "0"
      : `${globalStyles.spaces[100]} ${globalStyles.spaces[200]}`};
  position: relative;
  overflow: hidden;

  &.inside::before {
    content: "";
    position: absolute;
    left: 1%;
    top: 1%;
    width: 98%;
    height: 96%;
    pointer-events: none;
    background:
      linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%),
      lightgray 50% / cover no-repeat;
    z-index: 1;
  }

  @media (max-width: ${globalStyles.breackPoints.md}) {
    padding: 0;
  }

  .one-line {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    display: block;

    &.main-color:hover {
      text-decoration: underline;
    }

    &.center {
      display: flex;
      justify-content: center;
    }
  }

  & > a > img {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 2;
    object-fit: cover;
    border-radius: ${globalStyles.radius.cardRadiusCorner100};
    display: block;
    margin-bottom: 16px;

    @media (min-width: ${globalStyles.breackPoints.mobile}) {
      height: 296px;
    }

    @media (min-width: ${globalStyles.breackPoints.md}) {
      height: 312px;
    }

    &.smallImg {
      height: 280px;
      // border-radius: 0;
    }

    &.projectsList {
      height: 192px;
      margin-bottom: 1rem;

      @media (max-width: ${globalStyles.breackPoints.md}) {
        height: 390px;
        max-width: 100%;
      }

      @media (max-width: ${globalStyles.breackPoints.mobile}) {
        height: 139px;
        max-width: 100%;
      }
    }

    &.large {
      height: 400px;
    }

    &.superSmallImg {
      height: 224px;
      object-fit: cover;

      @media (max-width: ${globalStyles.breackPoints.md}) {
        height: 188px;
      }

      @media (max-width: ${globalStyles.breackPoints.tablet}) {
        height: 172px;
      }

      @media (max-width: ${globalStyles.breackPoints.mobile}) {
        height: 153px;
      }

      &.video {
        height: 190px;
        padding: 0rem;
        object-fit: cover;
        border-radius: 8px;

        @media (max-width: ${globalStyles.breackPoints.md}) {
          height: 247px;
          width: 100%;
        }

        @media (max-width: ${globalStyles.breackPoints.tablet}) {
          height: 176px;
        }
      }
    }

    @media (min-width: ${globalStyles.breackPoints.lg}) {
      &.large {
        height: 560px;
      }
    }
  }

  h3 {
    position: relative;
    z-index: 2;
    color: ${(props) => props.theme.colorTextPrimary};
    margin: 0;
    font-weight: 700;
    font-size: ${globalStyles.fonts.p14};
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;

    &.blogMax {
      max-height: 52px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &.projectsList {
      -webkit-line-clamp: 2;
      line-clamp: 2;
    }

    @media (min-width: ${globalStyles.breackPoints.lg}) {
      font-size: ${globalStyles.fonts.p16};
    }

    @media (min-width: ${globalStyles.breackPoints.tablet}) {
      font-size: ${globalStyles.fonts.b20};
      line-height: 1.3;
    }

    &.superSmallImg {
      @media (max-width: ${globalStyles.breackPoints.tablet}) {
        text-align: center;
      }
    }

    &.vlog {
      text-align: center;
      -webkit-line-clamp: 1;
      line-clamp: 1;
    }

    &.projectsList {
      font-size: 20px;
    }

    @media (max-width: ${globalStyles.breackPoints.lgTablet}) {
      &.projectsList {
        font-size: 16px;
        text-align: center;
      }
    }

    @media (max-width: ${globalStyles.breackPoints.mobile}) {
      &.projectsList {
        font-size: 14px;
      }
    }
  }

  p {
    color: ${(props) => props.theme.colorTextSubtle};
    margin: 8px 0;
    width: 100%;
    word-break: break-word;

    &.maxDescription {
      height: 75px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;

      &.blogMax {
        -webkit-line-clamp: 2;
        line-clamp: 2;
        height: 49px;
      }
    }

    &.vlog {
      text-align: center;
    }

    @media (max-width: ${globalStyles.breackPoints.md}) {
      font-size: ${globalStyles.fonts.p14};
      margin: ${globalStyles.spaces[100]} 0;

      &.blogMax.maxDescription,
      &.maxDescription {
        height: auto;
      }
    }
  }

  .font-weight-400 {
    font-weight: 400;
  }

  div.absolute {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    width: 80%;
    z-index: 2;

    p {
      margin: 0;
      color: ${(props) => props.theme.colorTextStacked};
    }

    h3 {
      color: ${(props) => props.theme.colorTextStacked};
      font-size: ${globalStyles.fonts.p14};

      @media (min-width: ${globalStyles.breackPoints.md}) {
        font-size: ${globalStyles.fonts.p16};
      }

      @media (min-width: ${globalStyles.breackPoints.tablet}) {
        font-size: ${globalStyles.fonts.b20};
      }
    }
  }

  div.video {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 192px;
    border-radius: 8px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );

    &.superSmallImg {
      height: 190px;

      @media (max-width: ${globalStyles.breackPoints.md}) {
        height: 214px;
      }

      &.video {
        @media (max-width: ${globalStyles.breackPoints.md}) {
          height: 247px;
        }

        @media (max-width: ${globalStyles.breackPoints.tablet}) {
          height: 176px;
        }
      }
    }

    img {
      width: 48px;
      height: 48px;
      position: relative;
      z-index: 3;
    }

    @media (max-width: ${globalStyles.breackPoints.lg}) {
      height: 183px;
    }

    @media (max-width: ${globalStyles.breackPoints.md}) {
      height: 163px;
    }

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      height: 230px;
    }

    @media (max-width: ${globalStyles.breackPoints.mobile}) {
      height: 148px;
    }
  }

  img.video {
    height: 192px;

    &.superSmallImg {
      height: 190px;

      @media (max-width: ${globalStyles.breackPoints.md}) {
        height: 247px;
      }

      @media (max-width: ${globalStyles.breackPoints.tablet}) {
        height: 176px;
      }
    }

    @media (max-width: ${globalStyles.breackPoints.lg}) {
      height: 183px;
    }

    @media (max-width: ${globalStyles.breackPoints.md}) {
      height: 163px;
    }

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      height: 230px;
    }

    @media (max-width: ${globalStyles.breackPoints.mobile}) {
      height: 148px;
    }
  }
`;

export const BlogHeadContainer = styled.div`
  background-color: ${(props) => props.theme.colorBackgroundNeutralBoldest};
  margin-top: -2px;

  @media (max-width: ${globalStyles.breackPoints.md}) {
    margin-top: -${globalStyles.spaces[400]};
    padding-top: ${globalStyles.spaces[1200]};
  }

  h2 {
    color: ${(props) => props.theme.colorTextInverse};
    font-size: ${globalStyles.fonts.display};
    margin-bottom: ${globalStyles.spaces[75]};

    @media (max-width: ${globalStyles.breackPoints.md}) {
      font-size: ${globalStyles.fonts.h2};
      margin-bottom: ${globalStyles.spaces[100]};
    }
  }

  p {
    color: ${(props) => props.theme.colorTextInverse};
    max-width: 780px;
  }

  button:hover {
    background-color: ${(props) =>
      props.theme.colorBackgroundBrandPrimaryBoldHovered} !important;
    color: #ebf3ff !important;
  }
`;

export const BlogFilterContainer = styled.div`
  margin-top: 80px;

  & > div {
    flex-wrap: wrap;
  }

  .blog-cats {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      overflow: scroll;
      padding: 0 calc(8dvw / 2);

      & > div {
        display: block;
        min-width: 120%;
        min-width: auto;
        width: max-content;

        button {
          margin-right: 16px;
        }
      }
    }

    @media (max-width: ${globalStyles.breackPoints.lgTablet}) {
      padding: 0 16px;
    }

    @media (max-width: ${globalStyles.breackPoints.mobile}) {
      & > div {
        button {
          font-size: 16px;
          padding: 12px 24px;
        }
      }
    }
  }

  @media (max-width: ${globalStyles.breackPoints.lgTablet}) {
    margin-top: 40px;
  }

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    margin-top: 32px;
  }
`;
