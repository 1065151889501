/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { t } from "i18next";
import { ICategory, IFilters } from "types/category";
import Input from "components/styled/Input";
import searchIcon from "assets/icons/searchIcon.svg";
import { ReactComponent as ArrowDown } from "assets/images/CaretDownDark.svg";
import { FilterList } from "./styled";
import CheckFilter from "./CheckFilter";
import { currentLangUrl } from "services/hooks/canonical";

interface IProps {
  categories?: ICategory[];
  filtersData: IFilters;
  filters: {
    models: string[];
    brands: string[];
  };
  onActive?: (slug: string) => void;
  setFilters: Dispatch<
    SetStateAction<{
      models: string[];
      brands: string[];
    }>
  >;
}

const CarFilter: FC<IProps> = ({
  categories,
  filtersData,
  filters,
  onActive,
  setFilters,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const cat = searchParams.get("cat");
  const { id, brandId } = useParams();

  const [searchModels, setSearchModels] = useState("");
  const [filteredModels, setFilteredModels] = useState(filtersData);
  const [activeCategories, setActiveCategories] = useState<string[]>([]);

  useEffect(() => {
    if (cat) {
      onActive && onActive(cat);
    }
  }, [cat]);

  useEffect(() => {
    setFilteredModels(filteredModels);
  }, [filtersData.brands, filtersData.models]);

  useEffect(() => {
    if (searchModels) {
      setFilteredModels({
        models: filtersData.models.filter((i) =>
          i.title?.toLowerCase().includes(searchModels.toLowerCase())
        ),
        brands: filtersData.brands.filter((i) =>
          i.title?.toLowerCase().includes(searchModels.toLowerCase())
        ),
      });
    } else {
      setFilteredModels(filtersData);
    }
  }, [searchModels, filtersData]);

  useEffect(() => {
    if (id) {
      setActiveCategories([id]);
    }
  }, [id]);

  return (
    <>
      <FilterList className="mb-600">
        {!!categories?.length && (
          <div className="mt-600 mb-500">
            {categories.map((item) => (
              <div key={item.id}>
                <div className="category pb-100">
                  <div
                    onClick={() =>
                      navigate(
                        location.pathname.includes("brand")
                          ? currentLangUrl() +
                              `/brand/${brandId}/products/${item.slug}`
                          : currentLangUrl() + `/products/${item.slug}`
                      )
                    }
                  >
                    {item.title}
                  </div>

                  {!!item?.children?.length && (
                    <div
                      onClick={() =>
                        setActiveCategories((state) =>
                          activeCategories.includes(item.slug) ||
                          activeCategories.some((i) =>
                            item?.children?.some((j) => j.slug === i)
                          )
                            ? state.filter(
                                (i) =>
                                  i !== item.slug &&
                                  item?.children?.length &&
                                  !item?.children.some((j) => j.slug === i)
                              )
                            : [...state, item.slug]
                        )
                      }
                    >
                      <ArrowDown
                        className={`${
                          activeCategories.includes(item.slug) ||
                          activeCategories.some((i) =>
                            item?.children?.some((j) => j.slug === i)
                          )
                            ? "arrow-up"
                            : ""
                        } cursor-pointer`}
                      />
                    </div>
                  )}
                </div>

                {!!item?.children?.length &&
                  (activeCategories.includes(item.slug) ||
                    activeCategories.some((i) =>
                      item?.children?.some((j) => j.slug === i)
                    )) &&
                  item?.children.map((sub) => (
                    <div
                      className={`sub-category pb-150 pl-200 ${
                        id === sub.slug ? "active" : ""
                      }`}
                      key={sub.id}
                      onClick={() => {
                        navigate(
                          location.pathname.includes("brand")
                            ? currentLangUrl() +
                                `/brand/${brandId}/products/${sub.slug}`
                            : currentLangUrl() + `/products/${sub.slug}`
                        );
                      }}
                    >
                      {sub.title}
                    </div>
                  ))}
              </div>
            ))}
          </div>
        )}

        {!!(filtersData.models.length || filtersData.brands.length) && (
          <div className="mb-300">
            <Input
              preIcon={searchIcon}
              placeholder={`${t("search")}...`}
              value={searchModels}
              onChange={(e) => setSearchModels(e.target.value)}
            />
          </div>
        )}

        {!!filteredModels.brands.length && (
          <CheckFilter
            data={filteredModels.brands}
            title={t("brand")}
            filters={filters.brands}
            setFilters={(data) =>
              setFilters((state) => ({ ...state, brands: data }))
            }
          />
        )}

        {!!filteredModels.models.length && (
          <CheckFilter
            data={filteredModels.models}
            title={t("model")}
            filters={filters.models}
            setFilters={(data) =>
              setFilters((state) => ({ ...state, models: data }))
            }
          />
        )}
      </FilterList>
    </>
  );
};

export default CarFilter;
