import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const VideoSliderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }

  .close {
    position: absolute;
    right: 48px;
    top: 48px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    @media (max-width: ${globalStyles.breackPoints.md}) {
      top: 25%;
      width: 32px;
      height: 32px;
    }

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      right: 4%;
      top: 25%;
    }
  }

  .img-container {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 70%;
    height: 60%;
    transform: translate(-50%, -50%);

    @media (max-width: ${globalStyles.breackPoints.md}) {
      width: 80%;
      height: 40%;
    }

    & > div,
    iframe,
    video {
      height: 100%;
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .arrow {
    position: absolute;
    left: 8%;
    top: 50%;
    transform: translateY(-50%);
    width: 84%;

    @media (max-width: ${globalStyles.breackPoints.md}) {
      width: 96%;
      left: 2%;
    }

    @media (max-width: ${globalStyles.breackPoints.mobile}) {
      width: 98%;
      left: 1%;
    }

    .arrow-container {
      width: 40px;
      height: 40px;
      cursor: pointer;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      @media (max-width: ${globalStyles.breackPoints.md}) {
        width: 32px;
        height: 32px;
      }

      &.right {
        float: right;
      }

      &.left {
        transform: rotate(180deg);
      }
    }
  }
`;
