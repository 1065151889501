import { useState } from "react";
import { t } from "i18next";
import { ReactComponent as ArrowDown } from "assets/images/CaretDownDark.svg";
import Checkbox from "../checkbox/checkbox";

interface IProps {
  data: { id: string; title: string }[];
  title: string;
  filters: string[];
  setFilters: (data: string[]) => void;
}

function CheckFilter({ data, title, filters, setFilters }: IProps) {
  const [showFilters, setShowFilters] = useState(true);
  const [showAllModels, setshowAllModels] = useState(false);

  return (
    <div className="mb-300">
      <div className="display-flex align-items-center justify-space-between mb-200">
        <div className="category">{title}</div>

        <div onClick={() => setShowFilters((state) => !state)}>
          <ArrowDown
            className={`${!showFilters ? "arrow-up" : ""} cursor-pointer`}
          />
        </div>
      </div>

      {showFilters && (
        <>
          {" "}
          <div className="filters-container">
            {!!data?.length && data.length > 4 && !showAllModels && (
              <>
                {data.slice(0, 4).map((item) => (
                  <Checkbox
                    key={item.id}
                    label={item.title}
                    checked={filters.includes(item.id.toString())}
                    onCheck={() =>
                      setFilters(
                        filters.includes(item.id.toString())
                          ? filters.filter(
                              (i) => i.toString() !== item.id.toString()
                            ) || []
                          : [...filters, item.id.toString()]
                      )
                    }
                  />
                ))}

                <div
                  className="showAllModels"
                  onClick={() => setshowAllModels(true)}
                >
                  {t("seeAll")}
                </div>
              </>
            )}

            {((!!data?.length && data.length <= 4) || showAllModels) && (
              <>
                {data.map((item) => (
                  <Checkbox
                    key={item.id}
                    label={item.title}
                    checked={filters.includes(item.id.toString())}
                    onCheck={() =>
                      setFilters(
                        filters.includes(item.id.toString())
                          ? filters.filter(
                              (i) => i.toString() !== item.id.toString()
                            ) || []
                          : [...filters, item.id.toString()]
                      )
                    }
                  />
                ))}
              </>
            )}
          </div>
          {((!!data?.length && data.length <= 4) || showAllModels) &&
            data.length > 4 && (
              <div
                className="showAllModels"
                onClick={() => setshowAllModels(false)}
              >
                {t("rollUp")}
              </div>
            )}
        </>
      )}
    </div>
  );
}

export default CheckFilter;
