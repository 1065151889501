import { Link, useNavigate } from "react-router-dom";
import { CategoriesContainer, HeaderSearchContainer } from "./styled";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as LogoEn } from "assets/icons/logo_en.svg";
import { ReactComponent as MenuList } from "assets/icons/List.svg";
import { ReactComponent as Phone } from "assets/icons/Phone2.svg";
import { ReactComponent as TopMenuIcon } from "assets/icons/top-menu.svg";
import MobileMenu from "./mobileMenu";
import { FC, RefObject, useEffect, useRef, useState } from "react";
import Input from "components/styled/Input";
import { useTranslation } from "react-i18next";
import { Button } from "components/styled/Button";
import { useOnClickOutside } from "helpers";
import { ICategories } from "types/project";
import { currentLangUrl } from "services/hooks/canonical";

interface IProps {
  dark: boolean;
  node: RefObject<HTMLDivElement>;
  categories: ICategories[];
  activeCategories: boolean;
  openGlobalMenu: () => void;
  openMenu: () => void;
  setActiveCategories: (active: boolean) => void;
}

const HeaderSearch: FC<IProps> = ({
  node,
  categories,
  activeCategories,
  setActiveCategories,
  openGlobalMenu,
  openMenu,
}) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const categoriesRef = useRef<HTMLDivElement>(null);
  const categoriesButtonRef = useRef<any>(null);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(false);
  const [subcategories, setSubCategories] = useState<{
    parent: string;
    data: ICategories[];
  } | null>(null);

  useEffect(() => {
    const handleBodyClass = () => {
      if (open) {
        document.body.classList.add("fixed");
      } else {
        document.body.classList.remove("fixed");
      }
    };

    handleBodyClass();
    return () => {
      document.body.classList.remove("fixed");
    };
  }, [open]);

  const onSubmit = () => {
    if (search) {
      navigate(currentLangUrl() + `/search?search=${search}`);
      setSearch("");
      setOpen(false);
      setShowSearch(false);
    }
  };

  useOnClickOutside(categoriesRef, (e) => {
    if (!categoriesButtonRef?.current.contains(e.target))
      setActiveCategories(false);
  });

  const toNavigate = (i: string) => {
    navigate(currentLangUrl() + `/products/${i}`);
    setActiveCategories(false);
  };

  return (
    <HeaderSearchContainer>
      <div className="display-flex align-items-center">
        <div className="mr-100 menu-list-icon" onClick={openMenu}>
          <MenuList />
        </div>

        <Link className="logo" to={currentLangUrl()}>
          {i18n.language === "en" ? (
            <LogoEn height={48} width={94} />
          ) : (
            <Logo height={48} width={94} />
          )}
        </Link>

        <div ref={categoriesButtonRef}>
          <Button
            size="lg"
            variant="base"
            className={`categories-button ml-400 ${activeCategories ? "active" : ""}`}
            onClick={() => setActiveCategories(!activeCategories)}
          >
            <MenuList />
            {t("categories")}
          </Button>
        </div>

        <a
          className="display-flex gap-150 ml-200 align-items-center phone-container"
          href="tel:+0322994455"
        >
          <Button size="sm" variant="base" className="phone-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M22.5347 16.4138C22.3675 17.6841 21.7436 18.8502 20.7796 19.6942C19.8155 20.5382 18.5772 21.0023 17.2959 21.0001C9.85216 21.0001 3.79591 14.9438 3.79591 7.50005C3.79361 6.21876 4.2578 4.98045 5.10178 4.01639C5.94576 3.05234 7.11182 2.42847 8.38216 2.2613C8.70339 2.22208 9.0287 2.2878 9.30952 2.44865C9.59034 2.60951 9.8116 2.85686 9.94028 3.1538L11.9203 7.57412V7.58537C12.0188 7.81267 12.0595 8.06083 12.0387 8.30769C12.0179 8.55455 11.9363 8.79242 11.8012 9.00005C11.7843 9.02537 11.7665 9.0488 11.7478 9.07224L9.79591 11.386C10.4981 12.8129 11.9906 14.2922 13.4362 14.9963L15.7181 13.0547C15.7405 13.0359 15.764 13.0184 15.7884 13.0022C15.9959 12.8639 16.2346 12.7794 16.4829 12.7565C16.7312 12.7336 16.9813 12.7729 17.2106 12.871L17.2228 12.8766L21.6393 14.8557C21.9368 14.9839 22.1848 15.205 22.3462 15.4858C22.5075 15.7667 22.5737 16.0922 22.5347 16.4138Z"
                fill="#44546F"
              />
            </svg>
          </Button>

          <div className="display-flex flex-column header-phone">
            <span className="f-p12">{t("contactUs")}</span>
            <a className="f-p16 color-default phone" href="tel:+0322994455">
              +032 2 99 44 55
            </a>
          </div>
        </a>
      </div>

      <div className="display-flex align-items-center">
        <a className="show-md icon-container" href="tel:+0322994455">
          <Phone />
        </a>

        <div className="desc-search">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={t("search")}
            />
          </form>

          <div className="icon" onClick={() => onSubmit()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M21.5306 20.4693L16.8365 15.7762C18.1971 14.1428 18.8755 12.0478 18.7307 9.92691C18.5859 7.80604 17.629 5.82265 16.0591 4.38932C14.4892 2.95599 12.4271 2.18308 10.3019 2.23138C8.17663 2.27968 6.15181 3.14547 4.64864 4.64864C3.14547 6.15181 2.27968 8.17663 2.23138 10.3019C2.18308 12.4271 2.95599 14.4892 4.38932 16.0591C5.82265 17.629 7.80604 18.5859 9.92691 18.7307C12.0478 18.8755 14.1428 18.1971 15.7762 16.8365L20.4693 21.5306C20.539 21.6003 20.6218 21.6556 20.7128 21.6933C20.8038 21.731 20.9014 21.7504 21 21.7504C21.0985 21.7504 21.1961 21.731 21.2871 21.6933C21.3782 21.6556 21.4609 21.6003 21.5306 21.5306C21.6003 21.4609 21.6556 21.3782 21.6933 21.2871C21.731 21.1961 21.7504 21.0985 21.7504 21C21.7504 20.9014 21.731 20.8038 21.6933 20.7128C21.6556 20.6218 21.6003 20.539 21.5306 20.4693ZM3.74997 10.5C3.74997 9.16495 4.14585 7.8599 4.88755 6.74987C5.62925 5.63984 6.68345 4.77467 7.91686 4.26378C9.15026 3.75289 10.5075 3.61922 11.8168 3.87967C13.1262 4.14012 14.3289 4.78299 15.2729 5.727C16.2169 6.671 16.8598 7.87374 17.1203 9.18311C17.3807 10.4925 17.247 11.8497 16.7362 13.0831C16.2253 14.3165 15.3601 15.3707 14.2501 16.1124C13.14 16.8541 11.835 17.25 10.5 17.25C8.71037 17.248 6.99463 16.5362 5.72919 15.2707C4.46375 14.0053 3.75195 12.2896 3.74997 10.5Z"
                fill="#44546F"
              />
            </svg>
          </div>
        </div>

        <div className="search">
          <div className={`search-container ${showSearch ? "active" : ""}`}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                borderBottom
                placeholder={`${t("search")}...`}
              />
            </form>

            <span onClick={() => setShowSearch(false)}>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="X">
                  <path
                    id="Vector"
                    d="M51.4142 48.5861C51.6 48.7719 51.7474 48.9925 51.8479 49.2353C51.9485 49.4781 52.0003 49.7383 52.0003 50.0011C52.0003 50.2639 51.9485 50.5241 51.8479 50.7669C51.7474 51.0097 51.6 51.2303 51.4142 51.4161C51.2283 51.6019 51.0077 51.7493 50.765 51.8499C50.5222 51.9505 50.262 52.0022 49.9992 52.0022C49.7364 52.0022 49.4762 51.9505 49.2334 51.8499C48.9906 51.7493 48.77 51.6019 48.5842 51.4161L31.9992 34.8286L15.4142 51.4161C15.0389 51.7914 14.5299 52.0022 13.9992 52.0022C13.4684 52.0022 12.9594 51.7914 12.5842 51.4161C12.2089 51.0408 11.998 50.5318 11.998 50.0011C11.998 49.4704 12.2089 48.9614 12.5842 48.5861L29.1717 32.0011L12.5842 15.4161C12.2089 15.0408 11.998 14.5318 11.998 14.0011C11.998 13.4704 12.2089 12.9614 12.5842 12.5861C12.9594 12.2108 13.4684 12 13.9992 12C14.5299 12 15.0389 12.2108 15.4142 12.5861L31.9992 29.1736L48.5842 12.5861C48.9594 12.2108 49.4684 12 49.9992 12C50.5299 12 51.0389 12.2108 51.4142 12.5861C51.7894 12.9614 52.0003 13.4704 52.0003 14.0011C52.0003 14.5318 51.7894 15.0408 51.4142 15.4161L34.8267 32.0011L51.4142 48.5861Z"
                    fill="#000"
                  />
                </g>
              </svg>
            </span>
          </div>

          <svg
            onClick={() => setShowSearch(true)}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21.5306 20.4696L16.8365 15.7765C18.1971 14.1431 18.8755 12.048 18.7307 9.92715C18.5859 7.80629 17.629 5.82289 16.0591 4.38956C14.4892 2.95623 12.4271 2.18333 10.3019 2.23163C8.17663 2.27993 6.15181 3.14571 4.64864 4.64888C3.14547 6.15205 2.27968 8.17687 2.23138 10.3021C2.18308 12.4274 2.95599 14.4894 4.38932 16.0593C5.82265 17.6293 7.80604 18.5861 9.92691 18.7309C12.0478 18.8757 14.1428 18.1973 15.7762 16.8368L20.4693 21.5308C20.539 21.6005 20.6218 21.6558 20.7128 21.6935C20.8038 21.7312 20.9014 21.7506 21 21.7506C21.0985 21.7506 21.1961 21.7312 21.2871 21.6935C21.3782 21.6558 21.4609 21.6005 21.5306 21.5308C21.6003 21.4612 21.6556 21.3784 21.6933 21.2874C21.731 21.1963 21.7504 21.0988 21.7504 21.0002C21.7504 20.9017 21.731 20.8041 21.6933 20.713C21.6556 20.622 21.6003 20.5393 21.5306 20.4696ZM3.74997 10.5002C3.74997 9.16519 4.14585 7.86015 4.88755 6.75011C5.62925 5.64008 6.68345 4.77492 7.91686 4.26403C9.15026 3.75314 10.5075 3.61946 11.8168 3.87991C13.1262 4.14036 14.3289 4.78324 15.2729 5.72724C16.2169 6.67125 16.8598 7.87398 17.1203 9.18335C17.3807 10.4927 17.247 11.8499 16.7362 13.0833C16.2253 14.3167 15.3601 15.3709 14.2501 16.1126C13.14 16.8543 11.835 17.2502 10.5 17.2502C8.71037 17.2482 6.99463 16.5364 5.72919 15.271C4.46375 14.0056 3.75195 12.2898 3.74997 10.5002Z"
              fill="#44546F"
            />
          </svg>
        </div>

        <div
          className="icon-container show-md"
          onClick={openGlobalMenu}
          ref={node}
        >
          <TopMenuIcon />
        </div>
      </div>

      <MobileMenu close={() => setOpen(false)} open={open} />

      {activeCategories && (
        <CategoriesContainer ref={categoriesRef}>
          <div className="categories p-200">
            {categories?.length &&
              categories.map((item) => (
                <div
                  className={
                    subcategories?.parent === item.slug ? "active" : ""
                  }
                  onMouseEnter={() =>
                    setSubCategories({
                      parent: item.slug,
                      data: item?.children || [],
                    })
                  }
                  onClick={() => toNavigate(item.slug)}
                >
                  {item.title}
                </div>
              ))}
          </div>

          <div className="sub-categories p-400">
            {subcategories?.data?.length ? (
              <>
                {subcategories?.data?.length < 8 ? (
                  <div className="column">
                    {subcategories.data.map((item) => (
                      <div key={item.id} onClick={() => toNavigate(item.slug)}>
                        {item.title}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="display-flex gap-500">
                    <div className="column column-item">
                      {subcategories.data?.map((item) => (
                        <div
                          key={item.id}
                          onClick={() => toNavigate(item.slug)}
                        >
                          {item.title}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </CategoriesContainer>
      )}
    </HeaderSearchContainer>
  );
};
export default HeaderSearch;
