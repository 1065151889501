/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { OffersContainer } from "./styled";
import { useEffect, useState } from "react";
import ListItem, { IListItem } from "components/parts/news";
import { API } from "services/API/API";
import { dataConvertStandart } from "helpers";
import { useCanonicalUrl } from "services/hooks/canonical";
import DocumentMeta from "react-document-meta";
import { useTranslation } from "react-i18next";
import { ProjectTitle } from "consts/global";
import { BlogList } from "pages/Blog/styled";
import BlogFilter from "components/parts/news/filter";
import { ICategory } from "types/category";
import Pagination from "components/parts/pagination";
import { useParams } from "react-router-dom";

const Offers = () => {
  const { t, i18n } = useTranslation();
  const [data, setdata] = useState<IListItem[]>([]);
  const [cat, setCurrentCat] = useState("");
  const [category, setCategory] = useState<ICategory[]>([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(9);
  const [totalPage, setTotalPage] = useState(9);
  const { id } = useParams();

  const fetchData = (cat: string, page: number) => {
    API.offers.getOffers(page, cat || "", id).then((res) => {
      setPerPage(res ? res?.offersData?.pagination?.per_page : 9);
      setTotalPage(res ? res.offersData?.pagination?.total : 9);
      setCategory(res?.categoriesData);

      setdata(
        dataConvertStandart(
          res?.offersData?.data
            ? res?.offersData?.data?.map(
                (item: { url?: string; slug: string }) => {
                  item.url = "/offers/" + item?.slug;
                  return item;
                }
              )
            : []
        )
      );
    });
  };

  useEffect(() => {
    fetchData(cat, page);
  }, [i18n.language]);

  const url = useCanonicalUrl();
  const meta = {
    title: `${t("offers")} | ${ProjectTitle}`,
    description: t("offers"),
    canonical: url,
  };

  const onPageChange = (e: number) => {
    const element = document.getElementById("page-container");
    element?.scrollIntoView({ behavior: "smooth" });
    setPage(e);
    fetchData(cat, e);
  };

  localStorage.setItem("page-category", t("offers"));
  localStorage.removeItem("page-title");

  return (
    <DocumentMeta {...meta}>
      <OffersContainer id="page-container">
        <BlogFilter
          pageTitle="offers"
          onActive={(e) => {
            setCurrentCat(e);
            setPage(1);
            fetchData(e, 1);
          }}
          category={category}
        />

        <BlogList className="container row">
          {data.map((item, index) => (
            <div
              className="col-4 col-lg-4 col-sm-12 col-tablet-6"
              key={`offers-${index}`}
            >
              <ListItem
                blogMax
                withoutspace
                maxDescription
                smallImg
                {...item}
              />
            </div>
          ))}
        </BlogList>

        {totalPage > perPage && (
          <Pagination
            onChange={(e) => onPageChange(e)}
            page={page}
            perPage={perPage}
            totalPage={totalPage}
            className="container"
          />
        )}
      </OffersContainer>
    </DocumentMeta>
  );
};

export default Offers;
