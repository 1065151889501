import axiosInstance from "..";

const getHomeData = () => {
  return axiosInstance.get("homepage").then((res) => {
    return res.data;
  });
};

const getBrandHomeData = (slug: string) => {
  return axiosInstance.get(`brandlanding/${slug}`).then((res) => {
    return res.data;
  });
};

export const home = {
  getHomeData,
  getBrandHomeData,
};
