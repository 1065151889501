import { FC, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import { globalStyles } from "consts/spaces";
import { IHomeSlider } from "types/home";
import { Slide } from "components/styled/Slide";
import CarretRightSlider from "assets/icons/CaretRightSlider.svg";
import { Button } from "components/styled/Button";
import { removeHtmlTags } from "helpers";
import { useTranslation } from "react-i18next";
import Request from "../request";

const CarouselContainer = styled.div`
  .control-arrow.control-prev,
  .control-arrow.control-next {
    right: 3%;
    right: 3%;
    bottom: 45%;
    top: auto;
    z-index: 5;
    width: 40px;
    height: 40px;
    background: #fff !important;
    text-align: center;
    opacity: 1;
    border-radius: 4px;

    @media (max-width: ${globalStyles.breackPoints.tablet}) {
      display: none;
    }

    &:before {
      display: none;
    }

    img {
      width: 30px;
    }

    &:hover {
      background: transparent;
    }
  }

  .control-dots {
    & > li.dot {
      height: 8px;
      width: 8px;
      opacity: 1;
      background-color: ${(props) => props.theme.colorShapeInverse};
      border-radius: 50%;
      box-shadow: none;
      margin: 0 2px;

      &.selected {
        width: 24px;
        border-radius: 8px;
        background-color: ${(props) => props.theme.colorShapeInverse};
      }
    }
  }

  .control-arrow.control-prev {
    left: 3%;

    img {
      transform: rotate(-180deg);
    }
  }

  .light-primary-button {
    color: #172b4d;
    background: #fff3d6;
  }

  @media (max-width: 479px) {
    .buttons {
      flex-direction: column;
      gap: 4px;

      button {
        margin-bottom: 0;
      }
    }
  }

  .call-button {
    &:hover {
      background-color: #a1bdd914;
    }

    &.active {
      background-color: #a6c5e229;
    }
  }
`;

interface IProps {
  slider: IHomeSlider[];
  brand?: string;
}

const HomeSlider: FC<IProps> = ({ slider, brand }) => {
  const { t } = useTranslation();
  const [request, setRequest] = useState(0);

  return (
    <CarouselContainer>
      {!!slider?.length && (
        <Carousel
          showArrows
          emulateTouch
          infiniteLoop={false}
          showStatus={false}
          showIndicators={slider?.length === 1 ? false : true}
          showThumbs={false}
          renderArrowPrev={(clickHandler, hasPrev) => {
            return (
              <div
                className={`${
                  hasPrev ? "position-absolute" : "display-none"
                } control-arrow control-prev`}
                onClick={clickHandler}
              >
                <img alt="" src={CarretRightSlider} />
              </div>
            );
          }}
          renderArrowNext={(clickHandler, hasNext) => {
            return (
              <div
                className={`${
                  hasNext ? "position-absolute" : "display-none"
                } control-arrow control-next`}
                onClick={clickHandler}
              >
                <img alt="" src={CarretRightSlider} />
              </div>
            );
          }}
          swipeable={true}
          preventMovementUntilSwipeScrollTolerance={true}
        >
          {slider.map((item) => (
            <Slide key={item.id}>
              <img src={item?.media?.url} alt={item.title} />
              <div>
                <div className="container">
                  {brand && <h1>{brand}</h1>}
                  <h2>{item.title}</h2>
                  <p className="hide-mobile">
                    {removeHtmlTags(item.description)}
                  </p>
                  <div className="display-flex gap-200 buttons">
                    {item?.link && !brand && (
                      <Button
                        link={item?.link}
                        size="lg"
                        style={
                          brand
                            ? { background: "#FFD000", color: "#172B4D" }
                            : {}
                        }
                      >
                        {t("getStarted")}
                      </Button>
                    )}

                    {/* {!!brand && (
                      <Button
                        size="lg"
                        variant="base-white"
                        onClick={() => setRequest(1)}
                        style={
                          !item?.link
                            ? { background: "#FFD000", color: "#172B4D" }
                            : {}
                        }
                        className="call-button"
                      >
                        {t("requestACall")}
                      </Button>
                    )} */}

                    {/* <Button
                    link={item.link}
                    size="lg"
                    className="light-primary-button"
                  >
                    {t("getStarted")}
                  </Button> */}
                  </div>
                </div>
              </div>
            </Slide>
          ))}
        </Carousel>
      )}

      <Request open={request} close={() => setRequest(0)} brand />
    </CarouselContainer>
  );
};

export default HomeSlider;
