import { globalStyles } from "consts/spaces";
import styled from "styled-components";

export const Slide = styled.div`
  position: relative;
  height: 75vh;
  min-height: 600px;

  @media (max-width: ${globalStyles.breackPoints.mobile}) {
    height: 60vh;
    min-height: 400px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 184.94%
    );
  }

  img {
    object-fit: cover;
    height: 100%;
  }

  & > div {
    position: absolute;
    left: 0;
    bottom: ${globalStyles.spaces[900]};
    width: 100%;
    text-align: left;

    h1 {
      color: #ffffff;
      margin-bottom: 0.5rem;
      font-size: 40px;
      font-weight: 700;
      line-height: 48px;
      text-transform: uppercase;
    }

    h2 {
      color: ${(props) => props.theme.colorTextInverse};
      margin: 0 0 ${globalStyles.spaces[200]} 0;
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: 64px;

      @media (max-width: 1024px) {
        font-size: 32px;
        line-height: 40px;
      }

      @media (max-width: 743px) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    p {
      color: ${(props) => props.theme.colorTextInverse};
      margin: 0 0 ${globalStyles.spaces[400]} 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      max-width: 820px;
    }

    @media (max-width: ${globalStyles.breackPoints.mobile}) {
      bottom: ${globalStyles.spaces[400]};
      h2 {
        font-size: ${globalStyles.fonts.h1};
        line-height: ${globalStyles.fonts.display};
      }

      button {
        width: 100%;
        height: 48px;
        margin-bottom: 8px;
        margin-left: 0;
      }
    }
  }
`;
